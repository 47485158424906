import { Dispatch } from 'react'
import { Icon } from '@iconify/react'
import { Typography } from '@mui/material'

import './CountryInput.scss'

/**
 * Used to map the country code to the country icon
 */
const CountryIcons = {
  AU: 'emojione:flag-for-australia',
  US: 'emojione:flag-for-us-outlying-islands',
  CA: 'emojione:flag-for-canada',
  UK: 'emojione:flag-for-united-kingdom',
  NZ: 'emojione:flag-for-new-zealand'
}

/**
 * Used to map the country code to the country name text
 */
const CountryCodeToText = {
  AU: 'Australia',
  US: 'United States',
  CA: 'Canada',
  UK: 'United Kingdom',
  NZ: 'New Zeland'
}

/**
 * CountryInput renders a component which allows you to change the country selected.
 * This component is used in the create pet form.
 */
export const CountryInput = ({
  value
}: {
  /** Country code selected */
  value: 'US' | 'NZ' | 'UK' | 'CA' | 'AU'
  // Dispatch action that is handling the value state */
  setValue: Dispatch<typeof value>
}) => {
  return (
    <div className="country-input-div">
      <Icon style={{ fontSize: 32 }} icon={CountryIcons[value]} />
      <Typography>{CountryCodeToText[value]}</Typography>
    </div>
  )
}
