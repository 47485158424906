import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ContestCard, Loading, Breadcrumbs, Tabs } from '../../components'
import { useFetchContest } from '../../services/queries'

import { AllTab } from './AllTab/AllTab'
import { LocalTab } from './LocalTab/LocalTab'
import { PrizeTab } from './PrizeTab/PrizeTab'

import './ContestDetails.scss'

/** Contest Details Page. This page is rendering all the details about a contest. The contestID is being passed through URL params */
export const ContestDetails = () => {
  const [tab, setTab] = useState(0)
  const { contestID = '' } = useParams()
  const { data: contest, isLoading } = useFetchContest(contestID)

  // If the UI is still loading from the backend response, we need to display
  // a loading spinner. This way we avoid issues.
  if (isLoading || !contest) {
    return (
      <div className="contest-details-page">
        <Breadcrumbs previousOptions={[{ label: 'Contests', url: '/contests' }]} current="" />
        <Loading />
      </div>
    )
  }

  return (
    <div className="contest-details-page">
      <Breadcrumbs
        previousOptions={[{ label: 'Contests', url: '/contests' }]}
        current={contest.name}
      />
      <div className="content">
        <ContestCard contest={contest} />
        <Tabs options={['all', 'local', 'prizes']} value={tab} setValue={setTab} />
        {tab == 0 && <AllTab />}
        {tab == 1 && <LocalTab />}
        {tab == 2 && <PrizeTab contest={contest} />}
      </div>
    </div>
  )
}
