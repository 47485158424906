/* Login API call queries. Currently using React Query */

import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'

// CONTEXT
import { useAuth } from '../hooks'

import { postResolve } from './helper'

import { LoginResponse, CustomError } from '../../types'

// This mutation is used to login using email
export const useLoginMutation = () => {
  return useMutation(
    async ({ email }: { email: string }) =>
      await postResolve(`/auth/login`, {
        email
      }),
    {
      onSuccess: ({ token }: LoginResponse) => {
        localStorage.setItem('token', token)
      },
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Please check your inbox to login!')
      }
    }
  )
}

// This mutation is used to check if
// the current JWT is valid. If it's valid, the user
// is considered to be logged in.
export const useCheckJwtMutation = () => {
  const queryClient = useQueryClient()
  const { dispatchAuth } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return useMutation(
    async (body: any) => {
      dispatchAuth({ isLoading: true })
      return await postResolve(`/auth/check`, body)
    },
    {
      onSuccess: ({ confirmed }: { confirmed: boolean | null }) => {
        dispatchAuth({ isAuthenticated: !!confirmed })
        if (confirmed) {
          // User Email is confirmed
          queryClient.invalidateQueries()
          navigate(searchParams.get('next') ?? '/contests')
        } else {
          // Email is not confirmed. Show error
          toast.error('Please check your inbox to login!')
        }
      },
      onError: (error: CustomError) => {
        dispatchAuth({ isAuthenticated: false })
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      },
      onSettled: () => {
        dispatchAuth({ isLoading: false })
      }
    }
  )
}

// Mutation to resend email confirmation email
// Is used whenver the user asks to resend the 2FA email
export const useResendConfirmationMutation = () => {
  return useMutation(async (body: any) => await postResolve(`/auth/resend`, body), {
    onSuccess: () => {
      toast.success('Email sent! Check your inbox!')
    },
    onError: (error: CustomError) => {
      toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
    }
  })
}

// Mutation to handle Facebook login
export const useFacebookLoginMutation = () => {
  const queryClient = useQueryClient()
  const { dispatchAuth } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return useMutation(
    async ({ accessToken, userID }: { accessToken: string; userID: string }) => {
      dispatchAuth({ isLoading: true })
      return await postResolve(`/auth/facebook`, { accessToken, userID })
    },
    {
      onSuccess: ({ token }: LoginResponse) => {
        localStorage.setItem('token', token)

        dispatchAuth({ isAuthenticated: true })
        queryClient.invalidateQueries()
        navigate(searchParams.get('next') ?? '/contests')
      },
      onError: (error: CustomError) => {
        dispatchAuth({ isAuthenticated: false })
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      },
      onSettled: () => {
        dispatchAuth({ isLoading: false })
      }
    }
  )
}
