import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Components
import { NoPets } from './NoPets/NoPets'
import { Pets } from './Pets/Pets'
import { Loading, Button } from '../../components'

//Queries
import { useFetchMyPets } from '../../services/queries'

import { ReactComponent as PawIcon } from '../../assets/icons/paw.svg'

import './MyPets.scss'

// MyPets is rendering the My Pets page. This page is fetching all the pets from the current user.
// A Spinner is shown during the loading time. If the backend doesn't return any pet, the NoPets page will
// be rendered. If the user owns any pet, the Pets component will be rendered
export const MyPets = () => {
  const navigate = useNavigate()
  const { data: myPets = [], isLoading: isLoadingMyPets } = useFetchMyPets()

  const handleOnClickCreate = () => {
    navigate('/my-pets/create')
  }

  return (
    <div className="my-pets-page">
      <Typography variant="h3" className="my-pets-header-wrapper">
        My Pets
        <div className="mobile-show">
          <Button
            onClick={handleOnClickCreate}
            text="Add Pet"
            icon={<PawIcon />}
            style={{ width: 140 }}
          />
        </div>
      </Typography>
      <div className="my-pets-content">
        {isLoadingMyPets ? <Loading /> : myPets?.length ? <Pets pets={myPets} /> : <NoPets />}
        <div className="add-pet-bttn-wrapper mobile-hide">
          <Button
            onClick={handleOnClickCreate}
            text="Add Pet"
            icon={<PawIcon />}
            style={{ maxWidth: 320 }}
          />
        </div>
      </div>
    </div>
  )
}
