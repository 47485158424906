import { Typography, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { PetPhoto } from '../../components'

import { numberWithCommas } from '../../utils/formatHelpers'

// assets
import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'

import { PetSummary } from '../../types'

import './MyPetCard.scss'

/**
 * MyPetCard is used whenever we need to display a pet card that belongs to the current user.
 * It is rendering the pet name, photo, votes and breed_name currently.
 */
export const MyPetCard = ({ pet }: { pet: PetSummary }) => {
  const navigate = useNavigate()
  const { pet_name, photo_main, votes, breed_name, owner_name, pet_id } = pet

  const handleOnClick = () => {
    navigate(`/pets/${pet_id}/edit`)
  }

  return (
    <motion.button onClick={handleOnClick} whileHover={{ scale: 1.05 }} className="my-pet-card-div">
      <div className="photo-wrapper">
        <PetPhoto imageID={photo_main} style={{ position: 'absolute' }} variant="square" />
      </div>
      <div className="card-content">
        <div className="card-header">
          <Typography variant="h4">{pet_name}</Typography>
          <Typography>({breed_name})</Typography>
        </div>
        <div className="card-footer">
          <Typography>{owner_name}</Typography>
          <div className="votes-div">
            <VoteIcon />
            <Typography>{`${numberWithCommas(votes)} ${
              votes === 1 ? `Vote` : `Votes`
            }`}</Typography>
          </div>
        </div>
      </div>
      <div className="bttns-container">
        <IconButton onClick={handleOnClick}>
          <EditIcon />
        </IconButton>
      </div>
    </motion.button>
  )
}
