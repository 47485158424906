import { Typography } from '@mui/material'

// Icons
import Logo from '../../assets/logo.svg'
import FacebookIcon from '../../assets/social-media/facebook.svg'
import InstagramIcon from '../../assets/social-media/instagram.svg'

import './Footer.scss'

/**
 * This component renders the Footer. The footer should be in every main page.
 */
export const Footer = () => {
  return (
    <section className="footer">
      <div className="top-section">
        <img alt="Paws Contest" src={Logo} height={50} width={150} />
        <div className="txt-container">
          <a>Terms</a>
          <a>Privacy Policy</a>
        </div>
        <div className="social-media">
          <a>
            <img alt="Facebook" src={FacebookIcon} height={32} width={32} />
          </a>
          <a>
            <img alt="Instagram" src={InstagramIcon} height={32} width={32} />
          </a>
        </div>
      </div>
      <div className="bottom-section">
        <Typography>© 2021-2022 Paws Contest. All Rights Reserved</Typography>
      </div>
    </section>
  )
}
