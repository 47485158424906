import { useState } from 'react'
import { Typography } from '@mui/material'

import { PetPhoto, Loading, Button, RadioSelect } from '../../../components'

import { useFetchRandomPets } from '../../../services/queries'

import './FeaturedEntries.scss'

/** This is rendering the Featured Entries section in the About page. This component is
 * fetching random pets from the backend to display them.
 */
export const FeaturedEntries = ({
  handleClickOnEnterYourPet
}: {
  handleClickOnEnterYourPet: () => void
}) => {
  const [type, setType] = useState<'Cat' | 'Dog'>('Dog')
  const { data: pets = [], isLoading } = useFetchRandomPets(type)

  return (
    <div className="featured-entries">
      <div className="section-header">
        <Typography variant="h1">Featured Entries</Typography>
        <RadioSelect
          options={[
            { label: 'Dogs', value: 'Dog' },
            { label: 'Cats', value: 'Cat' }
          ]}
          value={type}
          setValue={setType}
        />
      </div>
      <div className="pets-container">
        {isLoading ? (
          <Loading />
        ) : (
          pets.map((pet, i) => (
            <PetPhoto
              key={`pet-photo-${i}`}
              imageID={pet.photo_main}
              variant="square"
              style={{ minWidth: 320, maxWidth: 320, height: 320 }}
            />
          ))
        )}
      </div>
      <Button onClick={handleClickOnEnterYourPet} text="ENTER YOUR PET" style={{ maxWidth: 326 }} />
    </div>
  )
}
