import { Typography, IconButton } from '@mui/material'

import { Button, ContestChip, CurrentlyWinning } from '../../../components'

//Context
import { useAuth } from '../../../services/hooks'

import { Pet, ContestPetDetails, ContestDetails } from '../../../types'

// Constants
import { StateList } from '../../../services/constants'

import { numberWithCommas } from '../../../utils/formatHelpers'

// ASSETS
import { ReactComponent as NextIcon } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as PreviousIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as VoteIcon } from '../../../assets/icons/vote.svg'
import { ReactComponent as MedalBadgeIcon } from '../../../assets/icons/medal-badge.svg'
import { ReactComponent as LocationFilledIcon } from '../../../assets/icons/location-filled.svg'

import './MobileContestPetCard.scss'

export const MobileContestPetCard = ({
  pet,
  petContestDetails,
  contest,
  handleOnClickNext,
  handleOnClickPrevious,
  onClickVote
}: {
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  contest: ContestDetails | undefined | null
  handleOnClickNext?: () => void
  handleOnClickPrevious?: () => void
  onClickVote?: () => void
}) => {
  const { name, breed_name, state } = pet
  const { overall_rank, local_rank, votes } = petContestDetails ?? {}
  const { isAuthenticated } = useAuth()

  return (
    <div className="mobile-contest-pet-card">
      <div className="mobile-contest-pet-card-header">
        <div className="name-div">
          <Typography variant="h4">{name}</Typography>
          <Typography>({breed_name})</Typography>
        </div>
        <div className="state-div">
          <LocationFilledIcon />
          <Typography>{StateList.find((s) => s.value === state)?.label ?? '--'}</Typography>
        </div>
      </div>
      <div className="chip-container">
        <ContestChip
          icon={<MedalBadgeIcon />}
          value={`${overall_rank ?? '-'} Place`}
          label="Overall"
        />
        <ContestChip icon={<MedalBadgeIcon />} value={`${local_rank ?? '-'} Place`} label={state} />
        <ContestChip
          icon={<VoteIcon />}
          value={votes == undefined ? '-' : numberWithCommas(votes)}
          label="Votes"
        />
      </div>
      {contest && (
        <CurrentlyWinning contest={contest} overall_rank={overall_rank} local_rank={local_rank} />
      )}
      <div className="vote-button-div">
        {handleOnClickPrevious && (
          <IconButton onClick={handleOnClickPrevious}>
            <PreviousIcon height={36} width={36} />
          </IconButton>
        )}
        <Button
          text={`Vote for ${name}`}
          onClick={onClickVote}
          disabled={!isAuthenticated || !contest}
        />
        {handleOnClickNext && (
          <IconButton onClick={handleOnClickNext}>
            <NextIcon height={36} width={36} />
          </IconButton>
        )}
      </div>
    </div>
  )
}
