import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'

// Components
import { Loading } from '../../components'
import { Photo } from './Photo/Photo'
import { ConnectFacebook } from './ConnectFacebook/ConnectFacebook'
import { ChangeAccountDetails } from './ChangeAccountDetails/ChangeAccountDetails'

//Queries
import { useFetchMyProfile, useDeleteMyProfile } from '../../services/queries'

import './MyAccount.scss'

export const MyAccount = () => {
  const deleteMyProfile = useDeleteMyProfile()
  const { data: profile, isLoading: isLoadingProfile, isFetched } = useFetchMyProfile()

  // Since this page is a PrivateRoute, this should never happen.
  // However, it's good to protect our code from errors.
  if (isFetched && !profile) {
    return <Navigate to="/" />
  }

  return (
    <div className="my-account-page">
      <Typography variant="h3">My Account</Typography>
      <div className="my-account-content">
        {isLoadingProfile ? (
          <Loading />
        ) : (
          <div className="my-account-container">
            <div className="add-photo">
              <Photo profile_picture={profile?.profile_picture} />
            </div>
            <ChangeAccountDetails profile={profile} />
            {!profile?.fb_id && (
              <div className="connect-fb">
                <ConnectFacebook />
              </div>
            )}
            <div className="delete-bttn">
              <button onClick={() => deleteMyProfile.mutate()}>
                <Typography variant="subtitle1">Delete Account</Typography>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
