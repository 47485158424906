import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoute } from './utils/PrivateRoute'
import axios, { AxiosRequestConfig } from 'axios'

// Pages
import {
  About,
  MyPets,
  CreatePet,
  Contests,
  ContestDetails,
  ContestPetDetails,
  Winners,
  PastContestDetails,
  Pet,
  MyAccount,
  MyAchievements,
  MyBonusVotes,
  MyPurchases,
  MyContests,
  Success,
  Search,
  NewPets,
  PopularPets,
  EditPet
} from './pages'

// Components
import { WelcomeModal } from './components'
import { Header, Footer, BottomTabs, MobileHeader, MobileMenu } from './layouts'

import { useFetchMyProfile } from './services/queries'

// CONTEXT
import { useAuth } from './services/hooks'

import './App.scss'

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (config?.headers && token) {
      config.headers.Auth = `${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { dispatchAuth, isAuthenticated } = useAuth()
  const { data: user, isFetched } = useFetchMyProfile()

  // Handle Mobile Screens vh This needs to be done in order to make sure our app is
  // responsive when the user opens the keyboard on mobile screens
  // Basically, I am setting the screen height as var(--vh) whenever
  // the screen size changes
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })

  useEffect(() => {
    if (!isFetched) {
      return
    }

    if (!user) {
      return dispatchAuth({ isAuthenticated: false, isLoading: false })
    }
    return dispatchAuth({ isAuthenticated: true, isLoading: false })
  }, [user, isFetched])

  // Shows a Spinner whenever the application is initializing
  // This will avoid unexpected errors and strange behaviours
  if (isAuthenticated === null) {
    return (
      <div className="main-div">
        <CircularProgress className="main-spinner" size={64} />
      </div>
    )
  }

  // This component has all the routes needed
  return (
    <>
      <div className="main-div">
        <Header />
        <MobileHeader />
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/contests" element={<Contests />} />
            <Route path="/contests/:contestID" element={<ContestDetails />} />
            <Route path="/contests/:contestID/pets/:petID" element={<ContestPetDetails />} />
            <Route path="/winners" element={<Winners />} />
            <Route path="/winners/:contestID" element={<PastContestDetails />} />
            <Route path="/pets/:petID" element={<Pet />} />
            <Route path="/search" element={<Search />} />
            <Route path="/new-pets" element={<NewPets />} />
            <Route path="/popular-pets" element={<PopularPets />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-pets" element={<MyPets />} />
              <Route path="/my-pets/create" element={<CreatePet />} />
              <Route path="/my-achievements" element={<MyAchievements />} />
              <Route path="/my-bonus-votes" element={<MyBonusVotes />} />
              <Route path="/my-purchases" element={<MyPurchases />} />
              <Route path="/my-contests" element={<MyContests />} />
              <Route path="/pets/:petID/edit" element={<EditPet />} />
            </Route>
            <Route path="/success" element={<Success />} />
          </Routes>
        </div>
        <Footer />
        <BottomTabs setMobileMenuOpen={setMobileMenuOpen} />
        <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
      </div>
      <WelcomeModal />
    </>
  )
}

export default App
