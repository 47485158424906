import { Typography, IconButton } from '@mui/material'

import { ReactComponent as FacebookIcon } from '../../../assets/social-media/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/social-media/instagram.svg'
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg'

// Types
import { Pet, ContestDetails } from '../../../types'

import './SharePet.scss'

export const SharePet = ({ pet, contest }: { pet: Pet; contest: ContestDetails }) => {
  const URL = `https://${window.location.hostname}/contests/${contest.contest_id}/pets/${pet.id}`

  return (
    <div className="share-vote-div">
      <div className="share-vote-header">
        <Typography variant="h5">{`Help ${pet.name} by sharing`}</Typography>
      </div>
      <div className="share-content">
        <Typography>Pets that are shared on social media get over 3x more votes!</Typography>
        <div className="share-container">
          <a>
            <FacebookIcon height={48} width={48} />
          </a>
          <a>
            <InstagramIcon height={48} width={48} />
          </a>
        </div>
      </div>
      <div className="link-wrapper">
        <Typography variant="subtitle2">{URL}</Typography>
        <IconButton sx={{ padding: 0 }} onClick={() => navigator.clipboard.writeText(URL)}>
          <CopyIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
    </div>
  )
}
