import { Typography } from '@mui/material'

import { CreatePetForm } from './CreatePetForm/CreatePetForm'

import './CreatePet.scss'

// CreatePet renders the Create Pet page
export const CreatePet = () => {
  return (
    <div className="create-pet-page">
      <Typography variant="h3">Create Pet</Typography>
      <CreatePetForm />
    </div>
  )
}
