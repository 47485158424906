import { useState, useEffect, Dispatch } from 'react'
import { Modal, IconButton } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { PaymentMethod } from './PaymentMethod/PaymentMethod'
import { SuccessVote } from './SuccessVote/SuccessVote'
import { SharePet } from './SharePet/SharePet'

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

// Types
import { Pet, ContestDetails, ContestPetDetails } from '../../types'

import './VoteModal.scss'

export const MobileVoteModal = ({
  open,
  handleClose,
  contest,
  pet,
  petContestDetails,
  selectedVotes,
  selectedMeals,
  selectedPrice,
  setSelectedVotes
}: {
  open: boolean
  handleClose: () => void
  contest: ContestDetails
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  selectedVotes: number
  selectedMeals: number
  selectedPrice: number
  setSelectedVotes: Dispatch<number>
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [step, setStep] = useState('choose-payment-method')

  const closeModal = () => {
    // We need to clear Status from the URL
    if (searchParams?.get('status')) {
      searchParams.delete('status')
      searchParams.delete('votes')
      setSearchParams(searchParams)
    }
    handleClose()
  }

  useEffect(() => {
    if (searchParams?.get('votes')) {
      setSelectedVotes(Number(searchParams?.get('votes')))
    }
    if (searchParams?.get('status') === 'SUCCESS') {
      return setStep('success')
    }
    setStep('choose-payment-method')
  }, [open, searchParams])

  if (
    step !== 'success' &&
    step !== 'share' &&
    (!selectedVotes || !selectedMeals || !selectedPrice)
  ) {
    return <></>
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <div className="vote-modal">
        {step === 'choose-payment-method' && (
          <PaymentMethod
            pet={pet}
            petContestDetails={petContestDetails}
            contest={contest}
            votesToBuy={selectedVotes ?? 0}
            price={selectedPrice ?? 0}
            meals={selectedMeals ?? 0}
          />
        )}
        {step === 'success' && <SuccessVote setStep={setStep} pet={pet} votes={selectedVotes} />}
        {step === 'share' && <SharePet pet={pet} contest={contest} />}
        <div className="vote-close-bttn">
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}
