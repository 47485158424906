// Pets mock data

import { MyPetsResponse, RandomPetsResponse, PetResponse } from '../../../types'

export const MyPets: MyPetsResponse = [
  {
    owner_name: 'Bernardo S.',
    pet_id: 2,
    pet_name: 'Kaiser',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 20000,
    local_rank: 1,
    overall_rank: 1,
    state: 'AL',
    country: 'US',
    breed_name: 'Boxer'
  },
  {
    owner_name: 'Bernardo S.',
    pet_id: 1,
    pet_name: 'Hugo',
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 2,
    overall_rank: 3,
    state: 'AL',
    country: 'US',
    breed_name: 'Affenpinscher'
  },
  {
    owner_name: 'Bernardo S.',
    pet_id: 4,
    pet_name: 'Mock',
    photo_main: '5282dc32-15c5-4c2a-1167-8a5c9124b000',
    votes: 1,
    local_rank: 1,
    overall_rank: 1,
    state: 'AK',
    country: 'US',
    breed_name: 'American Shorthair'
  }
]

export const RandomPets: RandomPetsResponse = [
  {
    id: 3,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 1,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 2,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 2,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 3,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 1,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 2,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 2,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  },
  {
    id: 2,
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    breed_name: 'French Bulldog',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    owner_name: 'Joe R.',
    owner_id: 1
  }
]

export const PetDetails: PetResponse = {
  owner_id: 1,
  owner_name: 'Bernardo',
  owner_picture: '08114130-9c46-4c06-c927-f747331d6700',
  id: 1,
  photo_main: 'df6570b2-e877-45eb-121e-2a602f266c00',
  photo_2: '',
  photo_3: '47e12b1b-f435-4016-964d-e85b3b673100',
  photo_4: '',
  breed_name: 'Affenpinscher',
  breed_id: 1,
  name: 'Lucas',
  state: 'NY',
  country: 'US',
  bio: 'MOCK',
  pet_type: 'Dog',
  total_votes: 0,
  gender: 'M'
}

export const PopularPets = [
  {
    owner_name: 'Bernardo P.',
    pet_id: 4,
    pet_name: 'Filipa',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 1,
    local_rank: 1,
    overall_rank: 1
  },
  {
    owner_name: 'Bernardo P.',
    pet_id: 1,
    pet_name: 'Lucas',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 0,
    local_rank: 1,
    overall_rank: 2
  }
]

export const NewPets = [
  {
    owner_name: 'Bernardo P.',
    pet_id: 1,
    pet_name: 'Lucas',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 0,
    local_rank: 1,
    overall_rank: 2
  },
  {
    owner_name: 'Bernardo P.',
    pet_id: 4,
    pet_name: 'Filipa',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 1,
    local_rank: 1,
    overall_rank: 1
  }
]
