/* Pets API call queries. Currently using React Query */

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { fetchResolve, postResolve, patchResolve } from './helper'

import { MyPetsResponse, CreatePetBody, PetResponse, CustomError } from '../../types'
import { SearchHistoryReponse, EditPetBody, Pet } from '../../types'

// Query used to get pets from the user logged in
export function useFetchMyPets() {
  return useQuery<MyPetsResponse>([`useFetchMyPets`], async () => fetchResolve(`/profile/pets`))
}

// Query used to create a new pet
export function useCreatePetMutation() {
  const queryClient = useQueryClient()
  return useMutation(async (body: CreatePetBody) => await postResolve(`/pet`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries('useFetchMyPets')
    },
    onError: () => {
      toast.error("Couldn't create pet. Try again later!")
    }
  })
}

// Query used to get 9 random pets
export function useFetchRandomPets(type: 'Dog' | 'Cat') {
  return useQuery<MyPetsResponse>([`useFetchRandomPets`, type], async () =>
    fetchResolve(`/pet/${type}/random`)
  )
}

// Query used to get pet details
export function useFetchPet(petID: string) {
  return useQuery<PetResponse>([`useFetchPet`, petID], async () => fetchResolve(`/pet/${petID}`))
}

// Query used to get popular pets
export function useFetchPopularPets(type: 'Cat' | 'Dog') {
  return useQuery<SearchHistoryReponse>([`useFetchPopularPets`, type], async () =>
    fetchResolve(`/pet/${type}/popular`)
  )
}

// Query used to get the newest pets
export function useFetchNewestPets(type: 'Cat' | 'Dog') {
  return useQuery<SearchHistoryReponse>([`useFetchNewestPets`, type], async () =>
    fetchResolve(`/pet/${type}/new`)
  )
}

// Mutation used to edit a pet
export function usePatchPetMutation(petID: number | string) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(async (body: EditPetBody) => await patchResolve(`/pet/${petID}`, body), {
    onSuccess: (data: Pet) => {
      queryClient.invalidateQueries()
      queryClient.setQueryData(['useFetchPet', petID], data)
      toast.success('Pet updated!')
      navigate('/my-pets')
    },
    onError: (error: CustomError) => {
      toast.error(error.data?.error?.message ?? 'Could not update your pet! Try again!')
    }
  })
}
