import { Typography } from '@mui/material'

import './ContestChip.scss'

export const ContestChip = ({
  icon,
  value,
  label
}: {
  icon: any
  value: string | number
  label: string
}) => (
  <div className="contest-chip">
    {icon}
    <div className="contest-chip-content">
      <Typography variant="h6">{value}</Typography>
      <Typography>{label}</Typography>
    </div>
  </div>
)
