export const VotePackages = [
  {
    price: 1.99,
    meals: 1,
    votes: 100
  },
  {
    price: 4.99,
    meals: 2,
    votes: 300
  },
  {
    price: 5.49,
    meals: 5,
    votes: 500,
    isRecommended: true
  },
  {
    price: 9.99,
    meals: 10,
    votes: 1000
  },
  {
    price: 18.99,
    meals: 20,
    votes: 2000
  },
  {
    price: 49.99,
    meals: 50,
    votes: 5000
  },
  {
    price: 99.99,
    meals: 100,
    votes: 10000
  },
  {
    price: 189.99,
    meals: 200,
    votes: 20000,
    pink: true
  }
]
