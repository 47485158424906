import { Typography } from '@mui/material'

// Assets
import NoPetsImage from '../../../assets/pets/no-pets.jpg'

import './NoPets.scss'

// This component will render a NoPets screen.
export const NoPets = () => (
  <div className="no-pets-div">
    <Typography>You haven’t added your pet yet.</Typography>
    <img src={NoPetsImage} height={320} width={320} />
  </div>
)
