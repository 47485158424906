/* Vote API call queries. Currently using React Query */
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

import { fetchResolve, postResolve } from './helper'

import { CustomError, FreeVoteAvailabilityResponse, TodayVotersResponse } from '../../types'

// Query used to get Free Vote Availability
export function useFetchFreeVoteAvailiability() {
  return useQuery<FreeVoteAvailabilityResponse>([`useFetchFreeVoteAvailiability`], async () =>
    fetchResolve(`/profile/free-vote`)
  )
}

// Query used to get contest pet
export function useFreeVote(contestID: number, petID: number) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(
    async () => await postResolve(`/contest/${contestID}/pets/${petID}/free-vote`, {}),
    {
      onSuccess: () => {
        navigate(`/contests/${contestID}/pets/${petID}?status=SUCCESS&votes=1`)
        queryClient.invalidateQueries(['useFetchFreeVoteAvailiability'])
        queryClient.invalidateQueries(['useFetchContestPetDetails'])
        queryClient.invalidateQueries(['useFetchPet'])
      },
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      }
    }
  )
}

export function useFetchTodayVoters() {
  return useQuery<TodayVotersResponse>([`useFetchTodayVoters`], async () =>
    fetchResolve(`/profile/today-voters`)
  )
}
