import { useState, useEffect } from 'react'
import { toast } from 'react-hot-toast'

import { Avatar, Loading } from '../../../components'

import { fetchResolve } from '../../../services/queries'

// Queries
import { usePatchMyProfile } from '../../../services/queries'

// Assets
import { ReactComponent as AddPhotoIcon } from '../../../assets/my-account/add-photo.svg'
import { ReactComponent as ImageAddIcon } from '../../../assets/icons/image-add.svg'

import './Photo.scss'

export const Photo = ({ profile_picture }: { profile_picture?: string }) => {
  const patchMyProfile = usePatchMyProfile()
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [picture, setPicture] = useState('')

  const onEditPicture = async (e: any) => {
    try {
      setIsLoadingUpload(true)
      const file = e.target.files[0]

      if (!file) {
        return
      }
      if (file.size > 40000000) {
        toast.error('File should be smaller than 4MB')
        return
      }
      const uploadURL = await fetchResolve(`/images/direct-upload`)

      let formData = new FormData()
      formData.append('file', file)

      // Upload picture
      const res = await fetch(uploadURL, {
        method: 'POST',
        body: formData
      })
      const { result } = await res.json()

      setPicture(result.id)

      patchMyProfile.mutate(
        {
          profile_picture: result.id
        },
        {
          onSuccess: () => setIsLoadingUpload(false),
          onError: () => setIsLoadingUpload(false)
        }
      )
    } catch (e) {
      toast.error('Could not update your profile picture!')
      setIsLoadingUpload(false)
    }
  }

  useEffect(() => {
    setPicture(profile_picture ?? '')
  }, [profile_picture])

  if (isLoadingUpload) {
    return (
      <div className="account-photo-wrapper loading">
        <Loading />
      </div>
    )
  }

  if (!picture.length) {
    return (
      <div className="account-photo-wrapper">
        <label htmlFor="file-upload">
          <AddPhotoIcon height={200} width={200} />
        </label>
        <input id="file-upload" data-testid="file-upload" type="file" onChange={onEditPicture} />
      </div>
    )
  }

  return (
    <div className="account-photo-wrapper">
      <Avatar imageID={picture} height={200} width={200} />
      <label htmlFor="file-upload" className="change-photo-bttn">
        <ImageAddIcon style={{ color: 'var(--purple)' }} />
      </label>
      <input id="file-upload" data-testid="file-upload" type="file" onChange={onEditPicture} />
    </div>
  )
}
