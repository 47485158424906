import { Dispatch, useState } from 'react'
import { useQueryClient } from 'react-query'
import { IconButton, Typography, Modal, Collapse } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { MobileHeader } from '..'

import { ReactComponent as CollapseIcon } from '../../assets/icons/collapse.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as HomeIcon } from '../../assets/mobile-menu/home.svg'
import { ReactComponent as DogIcon } from '../../assets/mobile-menu/dog.svg'
import { ReactComponent as CatIcon } from '../../assets/mobile-menu/cat.svg'
import { ReactComponent as MedalIcon } from '../../assets/mobile-menu/medal.svg'
import { ReactComponent as PawIcon } from '../../assets/mobile-menu/paw.svg'
import { ReactComponent as InfoIcon } from '../../assets/mobile-menu/info.svg'
import { ReactComponent as LogoutIcon } from '../../assets/mobile-menu/logout.svg'
import { ReactComponent as AccountIcon } from '../../assets/mobile-menu/account.svg'

// Hooks
import { useAuth, useLoginModal } from '../../services/hooks'

import './MobileMenu.scss'

const MobileMenuSubItem = ({
  label,
  onClick,
  index
}: {
  label: string
  onClick: () => void
  index: number
}) => (
  <button
    className="mobile-menu-sub-item"
    style={{ marginTop: index === 0 ? 24 : 0 }}
    onClick={onClick}>
    <Typography variant="h6">{label}</Typography>
  </button>
)

const MobileMenuItem = ({
  icon,
  label,
  onClick,
  subMenu
}: {
  icon: React.ReactNode
  label: string
  onClick?: () => void
  subMenu?: any[]
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="mobile-menu-item-wrapper">
      <button
        className="mobile-menu-item"
        onClick={subMenu ? () => setExpanded((prev) => !prev) : onClick}>
        <div className="menu-item-left">
          {icon}
          <Typography variant="h5">{label}</Typography>
        </div>
        {!!subMenu && (
          <CollapseIcon className={`menu-collapse-icon ${expanded ? 'expanded' : 'contracted'}`} />
        )}
      </button>
      {subMenu?.length && (
        <Collapse in={expanded}>
          <div className="sub-menu-container">
            {subMenu.map((sub, i) => (
              <MobileMenuSubItem
                key={`${label}-sub-item-${i}`}
                label={sub.label}
                onClick={sub.onClick}
                index={i}
              />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  )
}

export const MobileMenu = ({ open, setOpen }: { open: boolean; setOpen: Dispatch<boolean> }) => {
  const navigate = useNavigate()
  const { isAuthenticated, dispatchAuth } = useAuth()
  const { dispatchLoginModal } = useLoginModal()
  const queryClient = useQueryClient()

  const handleLogout = () => {
    localStorage.removeItem('token')
    dispatchAuth({ isAuthenticated: false })
    queryClient.setQueryData(['useFetchMyProfile'], null)
    navigate('/')
    queryClient.invalidateQueries()
  }

  const handleOnClickLogin = () => {
    dispatchLoginModal({ isOpen: true })
  }

  const handleNavigate = (url: string) => {
    navigate(url)
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="mobile-menu">
        <IconButton onClick={() => setOpen(false)} className="close-menu-bttn">
          <CloseIcon />
        </IconButton>
        <MobileHeader hideSearch />
        <div className="mobile-menu-container-wrapper">
          <div className="mobile-menu-container">
            {!isAuthenticated && (
              <div className="login-div">
                <button onClick={handleOnClickLogin}>
                  <Typography variant="h5">Login</Typography>
                </button>
                <button onClick={handleOnClickLogin}>
                  <Typography variant="h5" color="primary">
                    Sign Up
                  </Typography>
                </button>
              </div>
            )}
            <MobileMenuItem label="Home" icon={<HomeIcon />} onClick={() => handleNavigate('/')} />
            <MobileMenuItem
              label="Dog Contest"
              icon={<DogIcon />}
              onClick={() => handleNavigate('/contests?contest-type=Dog')}
            />
            <MobileMenuItem
              label="Cat Contest"
              icon={<CatIcon />}
              onClick={() => handleNavigate('/contests?contest-type=Cat')}
            />
            <MobileMenuItem
              label="Winners"
              icon={<MedalIcon />}
              onClick={() => handleNavigate('/winners')}
            />
            {isAuthenticated && (
              <MobileMenuItem
                label="My Pets"
                icon={<PawIcon />}
                onClick={() => handleNavigate('/my-pets')}
              />
            )}
            <MobileMenuItem label="About" icon={<InfoIcon />} onClick={() => handleNavigate('/')} />
            {isAuthenticated && (
              <MobileMenuItem
                label="Account"
                icon={<AccountIcon />}
                subMenu={[
                  { label: 'My Account', onClick: () => handleNavigate('/my-account') },
                  { label: 'My Contests', onClick: () => handleNavigate('/my-contests') },
                  { label: 'My Achievements', onClick: () => handleNavigate('/my-achievements') },
                  { label: 'My Purchases', onClick: () => handleNavigate('/my-purchases') },
                  { label: 'My Bonus Votes', onClick: () => handleNavigate('/my-bonus-votes') }
                ]}
              />
            )}
            {isAuthenticated && (
              <MobileMenuItem label="Logout" icon={<LogoutIcon />} onClick={handleLogout} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
