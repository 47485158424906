/* Payment API call queries. Currently using React Query */

import { useMutation } from 'react-query'

import { postResolve } from './helper'

// This mutation to get a client secret in order to be able to
// process a payment through the frontend
export const usePaymentIntentMutation = () => {
  return useMutation(
    async ({ votes, petID, contestID }: { votes: number; petID: number; contestID: number }) =>
      await postResolve(`/payment/intent`, {
        votes,
        petID,
        contestID
      })
  )
}
