import moment from 'moment'
import { Typography } from '@mui/material'

import { ContestDetails } from '../../types'

import { FormatImageURL } from '../../utils/FormatImageURL'
import { numberWithCommas } from '../../utils/formatHelpers'

import './PastContestCard.scss'

/**
 * PastContestCard is used to show deatails about past contests. Since the information is different from a current contest, we can't use
 * the same component.
 */
export const PastContestCard = ({
  contest,
  variant = 'big',
  onClick
}: {
  /** contest object */
  contest: ContestDetails
  /** big or small just like as ContestCarda */
  variant?: 'big' | 'small'
  /** onClick action if needed */
  onClick?: () => void
}) => {
  const {
    start_date,
    end_date,
    prize_1st,
    prize_2nd,
    prize_3rd,
    prize_4th,
    contestants,
    image,
    name,
    background_color
  } = contest

  return (
    <button
      className="past-contest-card-div"
      style={{
        backgroundColor: background_color,
        backgroundImage: `url("${FormatImageURL(image, 'public')}")`
      }}
      disabled={!onClick}
      onClick={onClick}>
      <div className="top-div">
        <Typography variant={variant === 'small' ? 'h4' : 'h2'}>{name}</Typography>
        <div>
          <Typography variant="subtitle2">
            {`From `}
            <b>{`${moment(start_date).format('MMM Do')}`}</b>
            {` to `}
            <b>{`
  ${moment(end_date).format('MMM Do')}
              `}</b>
          </Typography>
        </div>
      </div>
      <div className="bottom-div">
        <Typography variant="h6">
          <b>{`$${numberWithCommas(prize_1st + prize_2nd + prize_3rd + prize_4th)} won`}</b>
        </Typography>
        <Typography variant="h6">
          <b>{`${numberWithCommas(contestants)} contestants`}</b>
        </Typography>
      </div>
    </button>
  )
}
