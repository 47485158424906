import { useState, useEffect } from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { PetPhoto } from '../../../components'

import { useFetchSearchHistory, useSearchMutation } from '../../../services/queries'

import { numberWithCommas, getNumberWithOrdinal } from '../../../utils/formatHelpers'

// Assets
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'

import { PetSummary } from '../../../types'

import './Search.scss'

/** The search component is used to get both Recently viewed pets and pets results from the backend
 * This is using the autocomplete component from mui.
 */
export const Search = () => {
  const navigate = useNavigate()
  const useSearch = useSearchMutation()
  const [value, setValue] = useState('')
  const [searchResults, setSearchResults] = useState<PetSummary[]>([])
  const [open, setOpen] = useState(false)
  const { data: history = [], isLoading: isLoadingHistory, refetch } = useFetchSearchHistory()

  // We need to keep the rencently viewed component with this function. Everytime the search bar is clicked we are going
  // to query the recently viewed pets.
  useEffect(() => {
    if (open) {
      refetch()
    }
  }, [open])

  return (
    <Autocomplete
      disablePortal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={(x) => x}
      getOptionLabel={(option: any) => option.pet_name}
      options={value.length >= 3 ? searchResults : history}
      loading={value.length >= 3 ? useSearch.isLoading : isLoadingHistory}
      onInputChange={(event, newInputValue) => {
        const sanitizedValue = newInputValue.trim()
        setValue(newInputValue)
        if (sanitizedValue.length < 3) {
          return
        }
        useSearch.mutate(sanitizedValue, {
          onSuccess: (data) => setSearchResults(data)
        })
      }}
      onChange={(event: any, newValue: any) => {
        if (!newValue) return
        navigate(`/pets/${newValue.pet_id}`)
      }}
      className="search-wrapper"
      freeSolo={value.length < 3 ? true : false}
      noOptionsText="No Pets"
      renderInput={(params) => (
        <TextField
          {...params}
          className="search-container"
          variant="outlined"
          placeholder="Search"
          sx={{
            '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ADADAD',
              opacity: 0.8
            },
            input: {
              fontSize: 14,
              color: 'var(--black)',
              '&::placeholder': {
                fontSize: 14
              },
              width: 200
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#ADADAD'
              }
            },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderColor: '#ADADAD', borderRadius: 2 }
            },
            '& .MuiOutlinedInput-root:hover': {
              '& > fieldset': {
                borderColor: '#ADADAD'
              }
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <SearchIcon style={{ width: 20, height: 20, color: `var(--black)` }} />
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option) => (
        <li
          style={{
            gap: 16
          }}
          {...props}>
          <PetPhoto
            imageID={option.photo_main}
            variant="square"
            style={{ height: 55, width: 55, borderRadius: 10 }}
          />
          <div className="search-result-txt-div">
            <Typography variant="h6">
              {`${option.pet_name}, `}
              <span style={{ fontWeight: 400, color: '#3B3C43' }}>{option.owner_name}</span>
            </Typography>
            <div style={{ display: 'flex', gap: 4 }}>
              {option.overall_rank && (
                <Typography variant="subtitle1">{`${getNumberWithOrdinal(
                  option.overall_rank
                )} - All •`}</Typography>
              )}
              <Typography variant="subtitle1">{`${numberWithCommas(
                option.votes
              )} Votes`}</Typography>
            </div>
          </div>
        </li>
      )}
    />
  )
}
