import { GetAllBreedsResponse } from '../../../types'

export const allBreeds: GetAllBreedsResponse = {
  dogs: [
    {
      id: 1,
      type: 'Dog',
      name: 'Affenpinscher'
    },
    {
      id: 2,
      type: 'Dog',
      name: 'Afghan Hound'
    },
    {
      id: 3,
      type: 'Dog',
      name: 'Airedale Terrier'
    },
    {
      id: 4,
      type: 'Dog',
      name: 'Akita'
    },
    {
      id: 5,
      type: 'Dog',
      name: 'Alaskan Malamute'
    },
    {
      id: 6,
      type: 'Dog',
      name: 'American Staffordshire Terrier'
    },
    {
      id: 7,
      type: 'Dog',
      name: 'American Water Spaniel'
    },
    {
      id: 8,
      type: 'Dog',
      name: 'Australian Cattle Dog'
    },
    {
      id: 9,
      type: 'Dog',
      name: 'Australian Shepherd'
    },
    {
      id: 10,
      type: 'Dog',
      name: 'Australian Terrier'
    },
    {
      id: 11,
      type: 'Dog',
      name: 'Basenji'
    },
    {
      id: 12,
      type: 'Dog',
      name: 'Basset Hound'
    },
    {
      id: 13,
      type: 'Dog',
      name: 'Beagle'
    },
    {
      id: 14,
      type: 'Dog',
      name: 'Bearded Collie'
    },
    {
      id: 15,
      type: 'Dog',
      name: 'Bedlington Terrier'
    },
    {
      id: 16,
      type: 'Dog',
      name: 'Bernese Mountain Dog'
    },
    {
      id: 17,
      type: 'Dog',
      name: 'Bichon Frise'
    },
    {
      id: 18,
      type: 'Dog',
      name: 'Black And Tan Coonhound'
    },
    {
      id: 19,
      type: 'Dog',
      name: 'Bloodhound'
    },
    {
      id: 20,
      type: 'Dog',
      name: 'Border Collie'
    },
    {
      id: 21,
      type: 'Dog',
      name: 'Border Terrier'
    },
    {
      id: 22,
      type: 'Dog',
      name: 'Borzoi'
    },
    {
      id: 23,
      type: 'Dog',
      name: 'Boston Terrier'
    },
    {
      id: 24,
      type: 'Dog',
      name: 'Bouvier Des Flandres'
    },
    {
      id: 25,
      type: 'Dog',
      name: 'Boxer'
    },
    {
      id: 26,
      type: 'Dog',
      name: 'Briard'
    },
    {
      id: 27,
      type: 'Dog',
      name: 'Brittany'
    },
    {
      id: 28,
      type: 'Dog',
      name: 'Brussels Griffon'
    },
    {
      id: 29,
      type: 'Dog',
      name: 'Bull Terrier'
    },
    {
      id: 30,
      type: 'Dog',
      name: 'Bulldog'
    },
    {
      id: 31,
      type: 'Dog',
      name: 'Bullmastiff'
    },
    {
      id: 32,
      type: 'Dog',
      name: 'Cairn Terrier'
    },
    {
      id: 33,
      type: 'Dog',
      name: 'Canaan Dog'
    },
    {
      id: 34,
      type: 'Dog',
      name: 'Cavalier King Charles Spaniel'
    },
    {
      id: 35,
      type: 'Dog',
      name: 'Cavadoodle'
    },
    {
      id: 36,
      type: 'Dog',
      name: 'Cavapoo'
    },
    {
      id: 37,
      type: 'Dog',
      name: 'Chesapeake Bay Retriever'
    },
    {
      id: 38,
      type: 'Dog',
      name: 'Chihuahua'
    },
    {
      id: 39,
      type: 'Dog',
      name: 'Chinese Crested'
    },
    {
      id: 40,
      type: 'Dog',
      name: 'Chinese Shar-Pei'
    },
    {
      id: 41,
      type: 'Dog',
      name: 'Chow Chow'
    },
    {
      id: 42,
      type: 'Dog',
      name: 'Clumber Spaniel'
    },
    {
      id: 43,
      type: 'Dog',
      name: 'Cocker Spaniel (American)'
    },
    {
      id: 44,
      type: 'Dog',
      name: 'Collie'
    },
    {
      id: 45,
      type: 'Dog',
      name: 'Curly-Coated Retriever'
    },
    {
      id: 46,
      type: 'Dog',
      name: 'Dachshund'
    },
    {
      id: 47,
      type: 'Dog',
      name: 'Dalmatian'
    },
    {
      id: 48,
      type: 'Dog',
      name: 'Doberman Pinscher'
    },
    {
      id: 49,
      type: 'Dog',
      name: 'English Cocker Spaniel'
    },
    {
      id: 50,
      type: 'Dog',
      name: 'English Setter'
    },
    {
      id: 51,
      type: 'Dog',
      name: 'English Springer Spaniel'
    },
    {
      id: 52,
      type: 'Dog',
      name: 'English Toy Spaniel'
    },
    {
      id: 53,
      type: 'Dog',
      name: 'Eskimo Dog'
    },
    {
      id: 54,
      type: 'Dog',
      name: 'Finnish Spitz'
    },
    {
      id: 55,
      type: 'Dog',
      name: 'Flat-Coated Retriever'
    },
    {
      id: 56,
      type: 'Dog',
      name: 'Fox Terrier'
    },
    {
      id: 57,
      type: 'Dog',
      name: 'Foxhound'
    },
    {
      id: 58,
      type: 'Dog',
      name: 'French Bulldog'
    },
    {
      id: 59,
      type: 'Dog',
      name: 'German Shepherd'
    },
    {
      id: 60,
      type: 'Dog',
      name: 'German Shorthaired Pointer'
    },
    {
      id: 61,
      type: 'Dog',
      name: 'German Wirehaired Pointer'
    },
    {
      id: 62,
      type: 'Dog',
      name: 'Goldendoodle'
    },
    {
      id: 63,
      type: 'Dog',
      name: 'Golden Retriever'
    },
    {
      id: 64,
      type: 'Dog',
      name: 'Gordon Setter'
    },
    {
      id: 65,
      type: 'Dog',
      name: 'Great Dane'
    },
    {
      id: 66,
      type: 'Dog',
      name: 'Greyhound'
    },
    {
      id: 67,
      type: 'Dog',
      name: 'Irish Setter'
    },
    {
      id: 68,
      type: 'Dog',
      name: 'Irish Water Spaniel'
    },
    {
      id: 69,
      type: 'Dog',
      name: 'Irish Wolfhound'
    },
    {
      id: 70,
      type: 'Dog',
      name: 'Jack Russell Terrier'
    },
    {
      id: 71,
      type: 'Dog',
      name: 'Japanese Spaniel'
    },
    {
      id: 72,
      type: 'Dog',
      name: 'Keeshond'
    },
    {
      id: 73,
      type: 'Dog',
      name: 'Kerry Blue Terrier'
    },
    {
      id: 74,
      type: 'Dog',
      name: 'Komondor'
    },
    {
      id: 75,
      type: 'Dog',
      name: 'Kuvasz'
    },
    {
      id: 76,
      type: 'Dog',
      name: 'Labrador Retriever'
    },
    {
      id: 77,
      type: 'Dog',
      name: 'Lakeland Terrier'
    },
    {
      id: 78,
      type: 'Dog',
      name: 'Lhasa Apso'
    },
    {
      id: 79,
      type: 'Dog',
      name: 'Maltese'
    },
    {
      id: 80,
      type: 'Dog',
      name: 'Manchester Terrier'
    },
    {
      id: 81,
      type: 'Dog',
      name: 'Mastiff'
    },
    {
      id: 82,
      type: 'Dog',
      name: 'Mexican Hairless'
    },
    {
      id: 83,
      type: 'Dog',
      name: 'Newfoundland'
    },
    {
      id: 84,
      type: 'Dog',
      name: 'Norwegian Elkhound'
    },
    {
      id: 85,
      type: 'Dog',
      name: 'Norwich Terrier'
    },
    {
      id: 86,
      type: 'Dog',
      name: 'Otterhound'
    },
    {
      id: 87,
      type: 'Dog',
      name: 'Papillon'
    },
    {
      id: 88,
      type: 'Dog',
      name: 'Pekingese'
    },
    {
      id: 89,
      type: 'Dog',
      name: 'Pointer'
    },
    {
      id: 90,
      type: 'Dog',
      name: 'Pomeranian'
    },
    {
      id: 91,
      type: 'Dog',
      name: 'Poodle'
    },
    {
      id: 92,
      type: 'Dog',
      name: 'Pug'
    },
    {
      id: 93,
      type: 'Dog',
      name: 'Puli'
    },
    {
      id: 94,
      type: 'Dog',
      name: 'Rhodesian Ridgeback'
    },
    {
      id: 95,
      type: 'Dog',
      name: 'Rottweiler'
    },
    {
      id: 96,
      type: 'Dog',
      name: 'Saint Bernard'
    },
    {
      id: 97,
      type: 'Dog',
      name: 'Saluki'
    },
    {
      id: 98,
      type: 'Dog',
      name: 'Samoyed'
    },
    {
      id: 99,
      type: 'Dog',
      name: 'Schipperke'
    },
    {
      id: 100,
      type: 'Dog',
      name: 'Schnauzer'
    },
    {
      id: 101,
      type: 'Dog',
      name: 'Scottish Deerhound'
    },
    {
      id: 102,
      type: 'Dog',
      name: 'Scottish Terrier'
    },
    {
      id: 103,
      type: 'Dog',
      name: 'Sealyham Terrier'
    },
    {
      id: 104,
      type: 'Dog',
      name: 'Sheepadoodle'
    },
    {
      id: 105,
      type: 'Dog',
      name: 'Shetland Sheepdog'
    },
    {
      id: 106,
      type: 'Dog',
      name: 'Shih Tzu'
    },
    {
      id: 107,
      type: 'Dog',
      name: 'Siberian Husky'
    },
    {
      id: 108,
      type: 'Dog',
      name: 'Silky Terrier'
    },
    {
      id: 109,
      type: 'Dog',
      name: 'Skye Terrier'
    },
    {
      id: 110,
      type: 'Dog',
      name: 'Staffordshire Bull Terrier'
    },
    {
      id: 111,
      type: 'Dog',
      name: 'Soft-Coated Wheaten Terrier'
    },
    {
      id: 112,
      type: 'Dog',
      name: 'Sussex Spaniel'
    },
    {
      id: 113,
      type: 'Dog',
      name: 'Spitz'
    },
    {
      id: 114,
      type: 'Dog',
      name: 'Tibetan Terrier'
    },
    {
      id: 115,
      type: 'Dog',
      name: 'Vizsla'
    },
    {
      id: 116,
      type: 'Dog',
      name: 'Weimaraner'
    },
    {
      id: 117,
      type: 'Dog',
      name: 'Welsh Terrier'
    },
    {
      id: 118,
      type: 'Dog',
      name: 'West Highland White Terrier'
    },
    {
      id: 119,
      type: 'Dog',
      name: 'Whippet'
    },
    {
      id: 120,
      type: 'Dog',
      name: 'Yorkshire Terrier'
    }
  ],
  cats: [
    {
      id: 121,
      type: 'Cat',
      name: 'Abyssinian'
    },
    {
      id: 122,
      type: 'Cat',
      name: 'American Bobtail'
    },
    {
      id: 123,
      type: 'Cat',
      name: 'American Curl'
    },
    {
      id: 124,
      type: 'Cat',
      name: 'American Shorthair'
    },
    {
      id: 125,
      type: 'Cat',
      name: 'American Wirehair'
    },
    {
      id: 126,
      type: 'Cat',
      name: 'Balinese'
    },
    {
      id: 127,
      type: 'Cat',
      name: 'Bengal'
    },
    {
      id: 128,
      type: 'Cat',
      name: 'Birman'
    },
    {
      id: 129,
      type: 'Cat',
      name: 'Bombay'
    },
    {
      id: 130,
      type: 'Cat',
      name: 'British Shorthair'
    },
    {
      id: 131,
      type: 'Cat',
      name: 'Burmese'
    },
    {
      id: 132,
      type: 'Cat',
      name: 'Calico'
    },
    {
      id: 133,
      type: 'Cat',
      name: 'Cornish Rex'
    },
    {
      id: 134,
      type: 'Cat',
      name: 'Devon Rex'
    },
    {
      id: 135,
      type: 'Cat',
      name: 'Domestic Long Hair'
    },
    {
      id: 136,
      type: 'Cat',
      name: 'Domestic Medium Hair'
    },
    {
      id: 137,
      type: 'Cat',
      name: 'Domestic Short Hair'
    },
    {
      id: 138,
      type: 'Cat',
      name: 'Himalayan'
    },
    {
      id: 139,
      type: 'Cat',
      name: 'Maine Coon'
    },
    {
      id: 140,
      type: 'Cat',
      name: 'Manx'
    },
    {
      id: 141,
      type: 'Cat',
      name: 'Munchkin'
    },
    {
      id: 142,
      type: 'Cat',
      name: 'Persian'
    },
    {
      id: 143,
      type: 'Cat',
      name: 'Ragdoll'
    },
    {
      id: 144,
      type: 'Cat',
      name: 'Russian Blue'
    },
    {
      id: 145,
      type: 'Cat',
      name: 'Scottish Fold'
    },
    {
      id: 146,
      type: 'Cat',
      name: 'Siamese'
    },
    {
      id: 147,
      type: 'Cat',
      name: 'Snowshoe'
    },
    {
      id: 148,
      type: 'Cat',
      name: 'Sphynx hairless'
    },
    {
      id: 149,
      type: 'Cat',
      name: 'Tabby'
    },
    {
      id: 150,
      type: 'Cat',
      name: 'Turkish Angora'
    },
    {
      id: 151,
      type: 'Cat',
      name: 'Turkish Van'
    },
    {
      id: 152,
      type: 'Cat',
      name: 'Tuxedo'
    }
  ]
}
