import { Dispatch, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, TextField } from '@mui/material'

import { Loading, PetRowItem } from '../../components'

import { useFetchSearchHistory, useSearchMutation } from '../../services/queries'

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'

import { PetSummary } from '../../types'

import './Search.scss'

const SearchInput = ({
  placeholder,
  value,
  setValue
}: {
  placeholder?: string
  value: string
  setValue: Dispatch<string>
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      inputProps={{ style: { fontSize: 14, opacity: 1, color: 'var(--black)' } }}
      value={value}
      onChange={handleChange}
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& > fieldset': {
            borderColor: '#ADADAD'
          }
        },
        '& .MuiOutlinedInput-root': {
          '& > fieldset': { borderColor: '#ADADAD', borderRadius: 2 }
        },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: '#ADADAD'
          }
        }
      }}
      InputProps={{
        endAdornment: <SearchIcon style={{ fontSize: '1.3rem', color: `var(--black)` }} />
      }}
    />
  )
}

export const Search = () => {
  const navigate = useNavigate()
  const useSearch = useSearchMutation()
  const [searchValue, setSearchValue] = useState('')
  const [value, setValue] = useState('')
  const [searchResults, setSearchResults] = useState<PetSummary[]>([])
  const { data: history = [], isLoading: isLoadingHistory } = useFetchSearchHistory()

  useEffect(() => {
    const sanitizedValue = searchValue.trim()
    setValue(sanitizedValue)
    if (sanitizedValue.length < 3) {
      return
    }
    useSearch.mutate(sanitizedValue, {
      onSuccess: (data) => setSearchResults(data)
    })
  }, [searchValue])

  const results = value.length < 3 ? history : searchResults

  return (
    <div className="search-page">
      <div className="search-wrapper">
        <SearchInput placeholder="Search" value={searchValue} setValue={setSearchValue} />
      </div>
      <div className="search-results-container">
        {value.length < 3 && isLoadingHistory ? (
          <Loading />
        ) : value.length >= 3 && useSearch.isLoading ? (
          <Loading />
        ) : results.length ? (
          results.map((r, i) => (
            <PetRowItem
              key={`search-result-${i}`}
              pet={r}
              onClick={() => navigate(`/pets/${r.pet_id}`)}
            />
          ))
        ) : (
          <Typography className="no-pets-txt" variant="h5">
            {value.length < 3 ? `No Recent Searches` : `No Search Results`}
          </Typography>
        )}
      </div>
    </div>
  )
}
