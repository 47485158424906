import { createServer } from 'miragejs'

// Data
import {
  allBreeds,
  MyProfile,
  LoginRes,
  CheckJWTRes,
  MyPets,
  CurrentContests,
  ContestDetails,
  ContestPets,
  RandomPets,
  MySearchHistory,
  PetDetails,
  MyAchievements,
  MyBonusVotes,
  MyPurchases,
  MyCurrentContests,
  MyFinishedContests,
  FreeVoteAvailability,
  PopularPets,
  NewPets,
  TodayVoters
} from './data'

// This function returns a mock server
// A mock server is used to separate all the frontend work from
// the backend work. This way we can mock responses easily everytime
// we need to add a new endpoint. This is really usefull for testing
// purposes as well!
export function makeServer() {
  let server = createServer({
    routes() {
      this.urlPrefix = 'http://mock.com'

      // AUTH
      this.post('/api/auth/login', () => {
        return LoginRes
      })

      this.post('/api/auth/check', () => {
        return CheckJWTRes
      })

      this.post('/api/auth/resend', () => {
        return {}
      })

      this.get(`/api/profile`, () => MyProfile)
      this.patch(`/api/profile`, () => MyProfile)
      this.delete(`/api/profile`, () => ({}))
      this.get(`/api/profile/today-voters`, () => TodayVoters)
      this.get(`/api/profile/my-votes`, () => TodayVoters)

      // PETS ENDPOINTS
      this.get(`/api/profile/pets`, () => MyPets)
      this.get(`/api/profile/free-vote`, () => FreeVoteAvailability)
      this.get(`/api/profile/achievements`, () => MyAchievements)
      this.get(`/api/profile/bonus-votes`, () => MyBonusVotes)
      this.get(`/api/profile/purchases`, () => MyPurchases)
      this.get(`/api/profile/contests/current`, () => MyCurrentContests)
      this.get(`/api/profile/contests/finished`, () => MyFinishedContests)
      this.post(`/api/pet`, () => ({}))
      this.get(`/api/pet/:id`, () => PetDetails)
      this.patch(`/api/pet/:id`, () => PetDetails)
      this.get(`/api/pet/:type/random`, () => RandomPets)
      this.get(`/api/pet/:type/popular`, () => PopularPets)
      this.get(`/api/pet/:type/new`, () => NewPets)

      //Contests
      this.get(`/api/contest/:type/current`, () => CurrentContests)
      this.get(`/api/contest/:type/past`, () => [CurrentContests])
      this.get(`/api/contest/:id`, () => ContestDetails)
      this.get(`/api/contest/:id/pets`, () => ContestPets)
      this.get(`/api/contest/:id/pets/:pet`, () => ContestPets[0])
      this.post(`/api/contest/:id/pets/:pet/free-vote`, () => ({ vote_id: 1 }))

      //Bonus Votes
      this.post(`/api/bonus-vote/:id/use`, () => ({}))

      //Achievements
      this.post(`/api/achievement/:id/use`, () => ({}))

      // Breed
      this.get(`/api/breed`, () => allBreeds)

      //SEARCH
      this.get(`/api/search/history`, () => MySearchHistory)
      this.post(`/api/search`, () => MySearchHistory.slice(1))

      // Image
      this.get(`api/images/direct-upload`, () => 'http://www.image.com')
      this.post(`http://www.image.com`, () => ({
        result: {
          id: 'fbeedba8-2d98-4c73-90cb-93e0a9661d00'
        }
      }))

      // PAYMENTS
      this.post(`/api/payment/intent`, () => ({
        clientSecret: 'pi_3LSKrYDdnuKvLuAl1JKvDvnA_secret_2k6TAy7ZPVItHdTe2CbOUMZSD'
      }))
    }
  })
  return server
}
