import { Typography } from '@mui/material'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { Button } from '../../../components'

// Queries
import { useConnectFacebook } from '../../../services/queries'

import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-square.svg'

import './ConnectFacebook.scss'

export const ConnectFacebook = () => {
  const connectFacebook = useConnectFacebook()

  const facebookCallback = ({ accessToken, userID }: any) => {
    connectFacebook.mutate({ accessToken, userID })
  }

  return (
    <div className="connect-facebook-div">
      <Typography>
        Connect your facebook account, it will allow us to secure your account and authenticate
        faster on Paws Contest
      </Typography>
      <FacebookLogin
        appId="1056617595241098"
        fields="id,short_name,gender,first_name,last_name,name,email,picture"
        callback={facebookCallback}
        render={({ onClick }: { onClick: any }) => (
          <Button onClick={onClick} text="Connect Facebook" icon={<FacebookIcon />} color="blue" />
        )}
      />
    </div>
  )
}
