import { MyContestsResponse } from '../../../types'

export const MyCurrentContests: MyContestsResponse = [
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-08-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Lucas',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 3,
    overall_rank: 1,
    overall_prize: null,
    state_prize: null
  },
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-08-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Lucas',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 3,
    overall_rank: 1,
    overall_prize: null,
    state_prize: null
  }
]

export const MyFinishedContests: MyContestsResponse = [
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-05-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 1000,
    local_rank: 1,
    overall_rank: 1,
    overall_prize: 10000,
    state_prize: 10
  },
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-05-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 1,
    overall_rank: 1,
    overall_prize: 10000,
    state_prize: 10
  },
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-05-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 1,
    overall_rank: 1,
    overall_prize: 10000,
    state_prize: 10
  },
  {
    contest_name: 'Dog Contest',
    contest_background_color: '#51A0AB',
    contest_start_date: '2022-04-30',
    contest_end_date: '2022-05-30',
    contest_id: 1,
    contest_image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 100,
    local_rank: 1,
    overall_rank: 1,
    overall_prize: 10000,
    state_prize: 10
  }
]
