import { Dispatch } from 'react'
import { Tabs as MUITabs, Tab } from '@mui/material'

/**
 * Tabs is a component that allows the user to navigate through multiple tabs inside the same page.
 */
export const Tabs = ({
  value,
  setValue,
  options
}: {
  value: number
  setValue: Dispatch<number>
  options: string[]
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <MUITabs value={value} onChange={handleChange} centered>
      {options.map((o) => (
        <Tab key={`tab-${o}`} label={o} sx={{ minWidth: '100px' }} />
      ))}
    </MUITabs>
  )
}
