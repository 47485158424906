import { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { toast } from 'react-hot-toast'

import { Button, Loading } from '../../../components'

// Types
import { Pet, ContestDetails } from '../../../types'

import './CheckoutForm.scss'

export function CheckoutForm({
  price,
  votes,
  contest,
  pet
}: {
  price: number
  votes: number
  pet: Pet
  contest: ContestDetails
}) {
  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        toast.error('Something went wrong.')
        return
      }

      switch (paymentIntent.status) {
        case 'succeeded':
          break
        case 'requires_payment_method':
          toast.error('Your payment was not successful, please try again.')
          break
        default:
          toast.error('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      setIsLoading(true)

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `https://${window.location.hostname}/contests/${contest.contest_id}/pets/${pet.id}?status=SUCCESS&votes=${votes}`
        }
      })

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        toast.error(error.message ?? 'An unexpected error occurred.')
      } else {
        toast.error('An unexpected error occurred.')
      }

      setIsLoading(false)
    } catch (e) {
      toast.error('Please try again! Error occurred.')
      setIsLoading(false)
    }
  }

  return (
    <div className="checkout-form-div">
      <div
        className="payment-form-wrapper"
        style={{ display: isLoading ? 'none' : 'flex', flexDirection: 'column', gap: 16 }}>
        <PaymentElement id="payment-element" />
        <Button
          text={`Pay $${price.toFixed(2)}`}
          onClick={handleSubmit}
          disabled={isLoading || !stripe || !elements}
        />
      </div>
      <div style={{ display: !isLoading ? 'none' : 'flex', height: '100%' }}>
        <Loading />
      </div>
    </div>
  )
}
