import { Typography } from '@mui/material'

// Components
import { Avatar, Button } from '..'

// Types
import { Achievement } from '../../types'

// Assets
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg'
import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'

import './AchievementCard.scss'

// AchievementCard is responsible for rendering a card per achievement. This card will show a
// "Use Votes" button enabled whenever the user is already owning the achievement and hasn't used it yet.
export const AchievementCard = ({
  achievement,
  onClick
}: {
  achievement: Achievement
  onClick: Function
}) => {
  const { votes, title, description, achievement_id, used } = achievement

  return (
    <div className="achievement-card-container">
      <Avatar imageID={achievement.picture} height={70} width={70} />
      <div className="details-container">
        <Typography variant="h6">{title}</Typography>
        <Typography>{description}</Typography>
        <div className="votes-div">
          <VoteIcon />
          <Typography>{`${votes} votes`}</Typography>
        </div>
      </div>
      <Button
        text="Use Votes"
        onClick={() => onClick(achievement_id)}
        style={{ width: 160 }}
        disabled={!!(used || !achievement_id)}
      />
      {!!achievement_id && <CheckmarkIcon className="checkmark-icon" />}
    </div>
  )
}
