import { useState } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { RadioSelect, PastContestCard, Loading } from '../../components'

import { useFetchPastContests } from '../../services/queries'

import './Winners.scss'

// This is rendering the Winners page.
export const Winners = () => {
  const [type, setType] = useState<'Cat' | 'Dog'>('Dog')
  const { data: pastContests = [], isLoading } = useFetchPastContests(type)
  const navigate = useNavigate()

  // Function called after clicking a contest card
  const handleOnClickContest = (id: number) => {
    navigate(`/winners/${id}`)
  }

  return (
    <div className="winners-page">
      <Typography variant="h3">Winners</Typography>
      <RadioSelect
        options={[
          { label: 'Dogs', value: 'Dog' },
          { label: 'Cats', value: 'Cat' }
        ]}
        value={type}
        setValue={setType}
      />
      <div className="winners-content">
        {isLoading ? (
          <Loading />
        ) : (
          pastContests.map((c) => (
            <PastContestCard
              key={`past-contest-card-${c.contest_id}`}
              contest={c}
              onClick={() => handleOnClickContest(c.contest_id)}
            />
          ))
        )}
      </div>
    </div>
  )
}
