/* Breed API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './helper'

import { GetAllBreedsResponse } from '../../types'

// Query used to get all available breeds
export function useFetchAllBreeds() {
  return useQuery<GetAllBreedsResponse>([`useFetchAllBreeds`], async () => fetchResolve(`/breed`))
}
