import { Profile } from '../../../types'

export const MyProfile: Profile = {
  id: 1,
  email: 'bernardo_prior@gmail.com',
  fb_id: null,
  fb_email: null,
  profile_picture: 'ce68338e-f3f1-464d-b771-901544861b00',
  name: 'bernardo_prior',
  number_of_pets: 0,
  pet_type: null,
  subscribe_notifications: 0,
  subscribe_updates: 0
}
