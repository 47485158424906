import { Typography } from '@mui/material'

import { Avatar } from '../../../components'

import { ReactComponent as VotesIcon } from '../../../assets/icons/vote.svg'

import { numberWithCommas } from '../../../utils/formatHelpers'

import { Vote } from '../../../types'

import './VoterItem.scss'

export const VoterItem = ({ vote }: { vote: Vote }) => {
  const { name, votes, profile_picture } = vote

  return (
    <div className="voter-item">
      <div className="voter-profile">
        <Avatar imageID={profile_picture} />
        <Typography variant="h6">{name}</Typography>
      </div>
      <div className="voter-votes">
        <VotesIcon />
        <Typography variant="body1">
          {`${numberWithCommas(votes)}`}
          <span className="mobile-hide">{` ${votes === 1 ? `Vote` : `Votes`}`}</span>
        </Typography>
      </div>
    </div>
  )
}
