import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

import { RadioSelect, ContestCard, Loading } from '../../components'

import { useFetchCurrentContest } from '../../services/queries'

import './Contests.scss'

// Contests is rendering all the contests currently open. Each contest is rendered using
// a ContestCard component. The user can switch between Dog and Cat contests.
export const Contests = () => {
  const [searchParams] = useSearchParams()
  const [type, setType] = useState<'Cat' | 'Dog'>('Dog')
  const { data: currentContest, isLoading } = useFetchCurrentContest(type)
  const navigate = useNavigate()

  const handleOnClickContest = (id: number) => {
    navigate(`/contests/${id}`)
  }

  useEffect(() => {
    if (searchParams?.get('contest-type')) {
      setType(searchParams.get('contest-type') as any)
    }
  }, [searchParams])

  return (
    <div className="contests-page">
      <Typography variant="h3">Contests</Typography>
      <div className="contest-type-wrapper">
        <RadioSelect
          options={[
            { label: 'Dogs', value: 'Dog' },
            { label: 'Cats', value: 'Cat' }
          ]}
          value={type}
          setValue={setType}
        />
      </div>
      <div className="content">
        {isLoading ? (
          <Loading />
        ) : currentContest ? (
          <ContestCard
            contest={currentContest}
            onClick={() => handleOnClickContest(currentContest.contest_id)}
          />
        ) : (
          <div className="no-contests-wrapper">
            <Typography variant="h6">No Contests</Typography>
          </div>
        )}
      </div>
    </div>
  )
}
