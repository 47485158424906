import { useState } from 'react'

import { Loading } from '..'

import './Image.scss'

export const Image = ({
  style,
  className,
  alt,
  src
}: {
  style: any
  className: string
  alt: string
  src: string
}) => {
  const [loading, setLoading] = useState(true)

  return (
    <>
      <div
        className={className}
        style={{
          ...style,
          display: loading && (!style?.height || style?.height > 50) ? 'flex' : 'none'
        }}>
        <Loading />
      </div>
      <img
        className={className}
        style={{ ...style, display: loading ? 'none' : 'block' }}
        alt={alt}
        src={src}
        onLoad={() => setLoading(false)}
      />
    </>
  )
}
