import { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { BioDetails } from '../BioDetails/BioDetails'
import { PhotoDetails } from '../PhotoDetails/PhotoDetails'
import { MobileContestPetCard } from '../MobileContestPetCard/MobileContestPetCard'
import { VoteRow } from '../../../pages/VoteModal/ChooseVotes/ChooseVotes'
import { MobileVoteModal } from '../../../pages/VoteModal/MobileVoteModal'

import { PetPhoto } from '../../../components'

import { useFetchFreeVoteAvailiability, useFreeVote } from '../../../services/queries'

import { VotePackages } from '../../../services/constants'

import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow-back.svg'

import { Pet, ContestDetails, ContestPetDetails } from '../../../types'

import './MobilePetDetails.scss'

export const MobilePetDetails = ({
  pet,
  petContestDetails,
  contest,
  handleOnClickNext,
  handleOnClickPrevious
}: {
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  contest: ContestDetails | undefined | null
  handleOnClickNext?: () => void
  handleOnClickPrevious?: () => void
}) => {
  const [searchParams] = useSearchParams()
  const [voteModalOpen, setVoteModalOpen] = useState(false)
  const [tab, setTab] = useState('details')
  const [price, setPrice] = useState(0)
  const [votes, setVotes] = useState(0)
  const [meals, setMeals] = useState(0)
  const { photo_main } = pet
  const freeVote = useFreeVote(contest?.contest_id ?? -1, pet.id)
  const { data: freeVoteAvailability } = useFetchFreeVoteAvailiability()

  const handleFreeVote = () => {
    freeVote.mutate()
    setTab('details')
  }

  const handleClickOnVotes = (votes: number, price: number, meals: number) => {
    setVotes(votes)
    setPrice(price)
    setMeals(meals)
    setVoteModalOpen(true)
    setTab('details')
  }

  useEffect(() => {
    if (searchParams?.get('status') === 'SUCCESS') {
      setVoteModalOpen(true)
    }
  }, [searchParams])

  return (
    <div className="mobile-pet-details">
      <div className="pet-photo-wrapper">
        <PetPhoto imageID={photo_main} variant="square" />
      </div>
      <div className="mobile-pet-details-card">
        {tab === 'details' && (
          <>
            <MobileContestPetCard
              pet={pet}
              petContestDetails={petContestDetails}
              contest={contest}
              handleOnClickNext={handleOnClickNext}
              handleOnClickPrevious={handleOnClickPrevious}
              onClickVote={() => setTab('votes')}
            />
            <BioDetails pet={pet} />
            <PhotoDetails pet={pet} />
          </>
        )}
        {tab === 'votes' && (
          <>
            <div className="mobile-choose-vote-bck">
              <IconButton onClick={() => setTab('details')}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className="vote-rows-container">
              <VoteRow
                price={0}
                meals={0}
                votes={1}
                onClick={handleFreeVote}
                availableAt={freeVoteAvailability?.availableAt}
              />
              {VotePackages.map((p, i) => (
                <VoteRow
                  key={`vote-row-${i}`}
                  price={p.price}
                  meals={p.meals}
                  votes={p.votes}
                  onClick={handleClickOnVotes}
                  pink={p.pink}
                  isRecommended={p.isRecommended}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {!!contest && (
        <MobileVoteModal
          open={voteModalOpen}
          handleClose={() => setVoteModalOpen(false)}
          contest={contest}
          pet={pet}
          petContestDetails={petContestDetails}
          selectedVotes={votes}
          selectedPrice={price}
          selectedMeals={meals}
          setSelectedVotes={setVotes}
        />
      )}
    </div>
  )
}
