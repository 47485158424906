import { Typography, IconButton } from '@mui/material'

import { PetPhoto, Button, ContestChip, CurrentlyWinning } from '../../../components'

import { Pet, ContestDetails, ContestPetDetails } from '../../../types'

//Context
import { useAuth } from '../../../services/hooks'

// ASSETS
import { ReactComponent as NextIcon } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as PreviousIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as VoteIcon } from '../../../assets/icons/vote.svg'
import { ReactComponent as MedalBadgeIcon } from '../../../assets/icons/medal-badge.svg'
import { ReactComponent as LocationFilledIcon } from '../../../assets/icons/location-filled.svg'

// Constants
import { StateList } from '../../../services/constants'

import { numberWithCommas } from '../../../utils/formatHelpers'

import './DesktopContestPetCard.scss'

// ContestPetCard is showing the summary of the current pet
// We are reusing this component for every Pet selected through the search mechanism and
// through a contest page. If the pet is not enrolled to the current contest, we are not showing
// any data about current places nor "Currently winning".
export const DesktopContestPetCard = ({
  pet,
  petContestDetails,
  contest,
  handleOnClickNext,
  handleOnClickPrevious,
  onClickVote
}: {
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  contest: ContestDetails | undefined | null
  handleOnClickNext?: () => void
  handleOnClickPrevious?: () => void
  onClickVote?: () => void
}) => {
  const { name, breed_name, state, photo_main } = pet
  const { overall_rank, local_rank, votes } = petContestDetails ?? {}
  const { isAuthenticated } = useAuth()

  return (
    <div className="contest-pet-card">
      <div className="pet-photo-wrapper">
        <PetPhoto imageID={photo_main} variant="square" />
      </div>
      <div className="txt-div">
        <div className="top-section">
          <div className="name-div">
            <Typography variant="h4">{name}</Typography>
            <Typography>({breed_name})</Typography>
          </div>
          <div className="state-div">
            <LocationFilledIcon />
            <Typography>{StateList.find((s) => s.value === state)?.label ?? '--'}</Typography>
          </div>
          <div className="chip-container">
            <ContestChip
              icon={<MedalBadgeIcon />}
              value={`${overall_rank ?? '-'} Place`}
              label="Overall"
            />
            <ContestChip
              icon={<MedalBadgeIcon />}
              value={`${local_rank ?? '-'} Place`}
              label={state}
            />
            <ContestChip
              icon={<VoteIcon />}
              value={votes == undefined ? '-' : numberWithCommas(votes)}
              label="Votes"
            />
          </div>
        </div>
        {contest && (
          <CurrentlyWinning contest={contest} overall_rank={overall_rank} local_rank={local_rank} />
        )}
        <div className="vote-button-div">
          {handleOnClickPrevious && (
            <IconButton onClick={handleOnClickPrevious}>
              <PreviousIcon height={36} width={36} />
            </IconButton>
          )}
          <Button
            text={`Vote for ${name}`}
            onClick={onClickVote}
            disabled={!isAuthenticated || !contest}
          />
          {handleOnClickNext && (
            <IconButton onClick={handleOnClickNext}>
              <NextIcon height={36} width={36} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}
