import { useNavigate, useSearchParams } from 'react-router-dom'

import { AboutUs } from './AboutUs/AboutUs'
import { HowItWorks } from './HowItWorks/HowItWorks'
import { FeaturedEntries } from './FeaturedEntries/FeaturedEntries'
import { FAQ } from './FAQ/FAQ'

// Hooks
import { useAuth, useLoginModal } from '../../services/hooks'

import './About.scss'

// ASSETS

/** This component is responsible for rendering the whole about page. This component is calling each section as a seperate component */
export const About = () => {
  const { isAuthenticated } = useAuth()
  const { dispatchLoginModal } = useLoginModal()
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const handleClickOnEnterYourPet = () => {
    if (!isAuthenticated) {
      setSearchParams({ next: '/my-pets' })
      dispatchLoginModal({ isOpen: true })
      return
    }

    navigate('/my-pets')
  }

  return (
    <div className="about-page">
      <AboutUs handleClickOnEnterYourPet={handleClickOnEnterYourPet} />
      <HowItWorks handleClickOnEnterYourPet={handleClickOnEnterYourPet} />
      <FeaturedEntries handleClickOnEnterYourPet={handleClickOnEnterYourPet} />
      <FAQ />
      <div className="legal"></div>
    </div>
  )
}
