import { useState, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { Loading, PetCard, RadioSelect } from '../../../components'

import { useFetchContestPets } from '../../../services/queries'

import { StateList } from '../../../services/constants/StateList'

import './LocalTab.scss'

// LocalTab is similiar to the AllTab. However it is only showing pets from the selected state
export const LocalTab = ({ isFinished }: { isFinished?: boolean }) => {
  const navigate = useNavigate()
  const { contestID = '' } = useParams()
  const { data: pets = [], isLoading } = useFetchContestPets(contestID)
  const [state, setState] = useState(StateList[0].value)

  const handlePetCardClick = (petID: number) => {
    navigate(`/contests/${contestID}/pets/${petID}`)
  }

  const petsFromState = useMemo(() => pets.filter((p) => p.state === state), [pets, state])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="local-tab-div">
      <div className="state-list-wrapper">
        <RadioSelect options={StateList} value={state} setValue={setState} />
      </div>
      <div className="pets-div">
        {petsFromState.length ? (
          petsFromState.map((pet) => (
            <PetCard
              key={`pet-${pet.id}`}
              pet={pet}
              onClick={!isFinished ? () => handlePetCardClick(pet.id) : undefined}
              variant="local"
            />
          ))
        ) : (
          <div className="no-pets-wrapper">
            <Typography variant="h6">None</Typography>
          </div>
        )}
      </div>
    </div>
  )
}
