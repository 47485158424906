import { useState, useEffect } from 'react'
import { Modal, IconButton } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { ChooseVotes } from './ChooseVotes/ChooseVotes'
import { PaymentMethod } from './PaymentMethod/PaymentMethod'
import { SuccessVote } from './SuccessVote/SuccessVote'
import { SharePet } from './SharePet/SharePet'

import { useFetchFreeVoteAvailiability, useFreeVote } from '../../services/queries'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

// Types
import { Pet, ContestDetails, ContestPetDetails } from '../../types'

import './VoteModal.scss'

export const VoteModal = ({
  open,
  handleClose,
  contest,
  pet,
  petContestDetails
}: {
  open: boolean
  handleClose: () => void
  contest: ContestDetails
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: freeVoteAvailability, isLoading } = useFetchFreeVoteAvailiability()
  const [step, setStep] = useState('choose-votes')
  const [numberVotes, setNumberVotes] = useState<number | null>(null)
  const [price, setPrice] = useState<number | null>(null)
  const [meals, setMeals] = useState<number | null>(null)
  const freeVote = useFreeVote(contest.contest_id, pet.id)

  const handleFreeVote = () => {
    freeVote.mutate()
  }

  const closeModal = () => {
    // We need to clear Status from the URL
    if (searchParams?.get('status')) {
      searchParams.delete('status')
      searchParams.delete('votes')
      setSearchParams(searchParams)
    }
    handleClose()
  }

  useEffect(() => {
    setNumberVotes(null)
    setPrice(null)
    setMeals(null)
    if (searchParams?.get('votes')) {
      setNumberVotes(Number(searchParams?.get('votes')))
    }
    if (searchParams?.get('status') === 'SUCCESS') {
      return setStep('success')
    }
    setStep('choose-votes')
  }, [open, searchParams])

  return (
    <Modal open={open} onClose={closeModal}>
      <div className="vote-modal">
        {step === 'choose-votes' && (
          <ChooseVotes
            setNumberVotes={setNumberVotes}
            setPrice={setPrice}
            setMeals={setMeals}
            setStep={setStep}
            pet={pet}
            isLoading={isLoading || freeVote.isLoading}
            freeVoteAvailability={freeVoteAvailability?.availableAt}
            handleFreeVote={handleFreeVote}
          />
        )}
        {step === 'choose-payament-method' && (
          <PaymentMethod
            pet={pet}
            petContestDetails={petContestDetails}
            contest={contest}
            votesToBuy={numberVotes ?? 0}
            price={price ?? 0}
            meals={meals ?? 0}
          />
        )}
        {step === 'success' && (
          <SuccessVote setStep={setStep} votes={numberVotes as number} pet={pet} />
        )}
        {step === 'share' && <SharePet pet={pet} contest={contest} />}
        <div className="vote-close-bttn">
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}
