import { Modal } from '@mui/material'

import { PetRowItem, Loading } from '../../components'

import { PetSummary } from '../../types'

import './ChoosePetModal.scss'

export const ChoosePetModal = ({
  open,
  handleClose,
  pets,
  onClick,
  isLoading
}: {
  open: boolean
  handleClose: () => void
  pets: PetSummary[]
  onClick: Function
  isLoading?: boolean
}) => {
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <div className="choose-pet-modal">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="pets-container">
            {pets.map((p, i) => (
              <PetRowItem key={`pet-${i}`} pet={p} onClick={() => onClick(p.pet_id)} />
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}
