import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

// Components
import { Button, Input, CheckboxInput } from '../../../components'

// UTILS
import { RegexValidators } from '../../../utils/Validators'

// Queries
import { usePatchMyProfile } from '../../../services/queries'

// Types
import { Profile } from '../../../types'

import './ChangeAccountDetails.scss'

export const ChangeAccountDetails = ({ profile }: { profile: Profile }) => {
  const patchMyProfile = usePatchMyProfile()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [winningNotifications, setWinningNotifications] = useState(false)
  const [importantUpdates, setImportantUpdates] = useState(false)

  useEffect(() => {
    setName(profile?.name ?? '')
    setEmail(profile?.email ?? '')
    setWinningNotifications(Boolean(profile?.subscribe_notifications) ?? false)
    setImportantUpdates(Boolean(profile?.subscribe_updates) ?? false)
  }, [profile])

  const buttonIsDisabled =
    (name === profile?.name &&
      email === profile?.email &&
      winningNotifications === Boolean(profile?.subscribe_notifications) &&
      importantUpdates === Boolean(profile?.subscribe_updates)) ||
    !email.match(RegexValidators.email)

  const handleOnClickSave = () => {
    // Apply mutation and reload the data on success
    patchMyProfile.mutate({
      name: name !== profile?.name ? name : undefined,
      email: email !== profile?.email ? email : undefined,
      subscribe_notifications:
        winningNotifications !== Boolean(profile?.subscribe_notifications)
          ? winningNotifications
          : undefined,
      subscribe_updates:
        importantUpdates !== Boolean(profile?.subscribe_updates) ? importantUpdates : undefined
    })
  }

  const handleOnClickCancel = () => {
    // Here we just need to set all the values to the initial ones
    setName(profile?.name ?? '')
    setEmail(profile?.email ?? '')
    setWinningNotifications(Boolean(profile?.subscribe_notifications) ?? false)
    setImportantUpdates(Boolean(profile?.subscribe_updates) ?? false)
  }

  return (
    <>
      <div className="form-fields">
        <div className="change-account-details-field">
          <Typography>Who is the owner of the pet?</Typography>
          <Input placeholder="Owner Name" value={name} setValue={setName} error={false} />
        </div>
        <div className="change-account-details-field">
          <Typography variant="h6">Login Information</Typography>
          <Input
            placeholder="Email"
            value={email}
            setValue={setEmail}
            error={!RegexValidators.email.test(email)}
          />
        </div>
      </div>
      <div className="notifications-form">
        <div className="change-account-details-field">
          <Typography variant="h6">Email Notification Setting</Typography>
          <div>
            <CheckboxInput
              value={winningNotifications}
              setValue={setWinningNotifications}
              label="Winning notifications"
            />
            <CheckboxInput
              value={importantUpdates}
              setValue={setImportantUpdates}
              label="Important Updates"
            />
          </div>
        </div>
        <div className="change-account-details-buttons">
          <Button onClick={handleOnClickSave} text="Save" disabled={buttonIsDisabled} />
          <Button onClick={handleOnClickCancel} text="Cancel" color="white" />
        </div>
      </div>
    </>
  )
}
