import { useState } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'

import { Loading, Button, ChoosePetModal } from '../../components'

import { useFetchMyBonusVotes, useFetchMyPets, useUseBonusVotes } from '../../services/queries'

import { StateList } from '../../services/constants/StateList'
import { getNumberWithOrdinal, numberWithCommas } from '../../utils/formatHelpers'

import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'

import './MyBonusVotes.scss'

export const MyBonusVotes = () => {
  const useBonusVotes = useUseBonusVotes()
  const [selectedBonusVote, setSelectedBonusVote] = useState<number | null>(null)
  const { data: bonusVotes = [], isLoading } = useFetchMyBonusVotes()
  const { data: myPets = [], isLoading: isLoadingMyPets } = useFetchMyPets()

  const handlePetOnClick = (petID: number) => {
    if (selectedBonusVote)
      useBonusVotes.mutate(
        { bonusVoteID: selectedBonusVote, petID },
        { onSuccess: () => setSelectedBonusVote(null) }
      )
  }

  return (
    <div className="my-bonus-votes-page">
      <Typography variant="h3">My Bonus Votes</Typography>
      <div className="my-bonus-votes">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {bonusVotes.map((bonus, i) => (
              <div key={`bonus-vote-${i}`}>
                <div className="bonus-vote-header">{`Contest ending ${moment(
                  bonus.contest_end_date
                ).format('MMMM DD, YYYY')}`}</div>
                <div className="bonus-vote-content">
                  <div className="left-side">
                    <VoteIcon style={{ width: 40, height: 40 }} />
                    <div className="bonus-vote-place-txt">
                      <Typography variant="h6">{`${numberWithCommas(
                        bonus.votes
                      )} Votes`}</Typography>
                      <Typography variant="body2">{`${getNumberWithOrdinal(
                        bonus.contest_place
                      )} Place - ${
                        bonus.contest_place_type === 'Global'
                          ? 'Overall'
                          : StateList.find((s) => s.value === bonus.contest_place_type)?.label ??
                            '--'
                      }`}</Typography>
                    </div>
                  </div>
                  <Button
                    onClick={() => setSelectedBonusVote(bonus.bonus_vote_id)}
                    text="Use Votes"
                    style={{ width: 150, padding: 8 }}
                    disabled={!!bonus.contest_entry_used_id}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <ChoosePetModal
        open={Boolean(selectedBonusVote)}
        handleClose={() => setSelectedBonusVote(null)}
        pets={myPets}
        onClick={handlePetOnClick}
        isLoading={isLoadingMyPets || useBonusVotes.isLoading}
      />
    </div>
  )
}
