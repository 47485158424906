import { createContext, useContext, useReducer } from 'react'

interface Action {
  isOpen?: boolean
}

interface IState {
  isOpen: boolean
}

interface ProviderProps {
  children: React.ReactNode
  options?: Action
}

interface IContext extends IState {
  dispatchLoginModal: Function
}

const initialState: IState = {
  isOpen: false
}

const LoginModalContext = createContext<IContext | undefined>(undefined)

function LoginModalReducer(state = initialState, action: Action) {
  return { ...state, ...action }
}

function LoginModalProvider({ children, options = {} }: ProviderProps) {
  const [stateLoginModal, dispatchLoginModal] = useReducer(LoginModalReducer, {
    ...initialState,
    ...options
  })

  return (
    <LoginModalContext.Provider value={{ ...stateLoginModal, dispatchLoginModal }}>
      {children}
    </LoginModalContext.Provider>
  )
}

function useLoginModal() {
  const context = useContext(LoginModalContext)
  if (context === undefined) {
    throw new Error(`useLoginModal must be used within a Provider`)
  }
  return context
}
export { LoginModalProvider, useLoginModal }
