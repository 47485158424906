import { Dispatch } from 'react'
import { Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

// Hooks
import { useAuth, useLoginModal } from '../../services/hooks'
import { useFetchMyProfile } from '../../services/queries'

// Assets
import { ReactComponent as HomeIcon } from '../../assets/tabs/home.svg'
import { ReactComponent as CatIcon } from '../../assets/tabs/cat.svg'
import { ReactComponent as DogIcon } from '../../assets/tabs/dog.svg'
import { ReactComponent as PawIcon } from '../../assets/tabs/paw.svg'
import { ReactComponent as BookmarkIcon } from '../../assets/tabs/bookmark.svg'
import { ReactComponent as StarIcon } from '../../assets/tabs/star.svg'
import { ReactComponent as MenuIcon } from '../../assets/tabs/menu.svg'

import './BottomTabs.scss'

const BottomTabItem = ({
  icon,
  label,
  onClick,
  isActive
}: {
  icon: any
  label: string
  onClick: () => void
  isActive: boolean
}) => (
  <button className={`bottom-tab-item ${isActive ? `active` : ``}`} onClick={onClick}>
    {icon}
    <Typography className="label-txt" variant="subtitle2">
      {label}
    </Typography>
  </button>
)

export const BottomTabs = ({ setMobileMenuOpen }: { setMobileMenuOpen: Dispatch<boolean> }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isAuthenticated } = useAuth()
  const { dispatchLoginModal } = useLoginModal()
  const { pathname } = useLocation()
  const { data: user } = useFetchMyProfile()

  return (
    <div className="bottom-tabs-wrapper">
      <BottomTabItem
        label="Home"
        icon={<HomeIcon />}
        onClick={() => navigate('/')}
        isActive={pathname === '/'}
      />
      {user?.pet_type === 'Cat' || searchParams?.get('contest-type') === 'Cat' ? (
        <BottomTabItem
          label="Cat"
          icon={<CatIcon />}
          onClick={() =>
            navigate(
              `/contests?contest-type=${
                searchParams?.get('contest-type') === 'Cat' ? 'Dog' : 'Cat'
              }`
            )
          }
          isActive={pathname === '/contests'}
        />
      ) : (
        <BottomTabItem
          label="Dog"
          icon={<DogIcon />}
          onClick={() =>
            navigate(
              `/contests?contest-type=${
                searchParams?.get('contest-type') === 'Dog' ? 'Cat' : 'Dog'
              }`
            )
          }
          isActive={pathname === '/contests'}
        />
      )}
      <BottomTabItem
        label="Add Pet"
        icon={<PawIcon />}
        onClick={() =>
          isAuthenticated ? navigate('/my-pets/create') : dispatchLoginModal({ isOpen: true })
        }
        isActive={pathname === '/my-pets/create'}
      />
      <BottomTabItem
        label={pathname !== '/new-pets' ? 'Popular' : 'New'}
        icon={pathname !== '/new-pets' ? <StarIcon /> : <BookmarkIcon />}
        onClick={() =>
          pathname !== '/popular-pets' ? navigate('/popular-pets') : navigate('/new-pets')
        }
        isActive={pathname === '/popular-pets' || pathname === '/new-pets'}
      />
      <BottomTabItem
        label="Menu"
        icon={<MenuIcon />}
        onClick={() => setMobileMenuOpen(true)}
        isActive={false}
      />
    </div>
  )
}
