import { Typography } from '@mui/material'

import { ReactComponent as MedalIcon } from '../../assets/contests/medal.svg'

import './MedalBadge.scss'

/**
 * MedalBadge displays a Medal with a text on the bottom if the place prop is provided.
 */
export const MedalBadge = ({ place, height = 40 }: { place?: number; height?: number }) => (
  <div className="medal-badge">
    <MedalIcon height={height} />
    {!!place && (
      <Typography
        sx={{ fontSize: '0.65rem', fontWeight: 500, margin: '0px 12px' }}
        variant="subtitle2">{`${place} PLACE`}</Typography>
    )}
  </div>
)
