import { Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { PetPhoto } from '../../../components'

import { ReactComponent as VotesIcon } from '../../../assets/icons/vote.svg'

import { numberWithCommas } from '../../../utils/formatHelpers'

import { Vote } from '../../../types'

import './VoteItem.scss'

export const VoteItem = ({ vote }: { vote: Vote }) => {
  const navigate = useNavigate()
  const { pet_name, pet_photo_main, votes, created, pet_id } = vote

  return (
    <button className="vote-item" onClick={() => navigate(`/pets/${pet_id}`)}>
      <div className="vote-pet">
        <PetPhoto
          style={{ width: 50, height: 50, borderRadius: 10, border: `1px solid white` }}
          imageID={pet_photo_main}
          variant="square"
        />
        <div>
          <Typography variant="h6">{pet_name}</Typography>
          <Typography variant="body2">{moment(created).format('MMMM DD, YYYY')}</Typography>
        </div>
      </div>
      <div className="vote-votes">
        <VotesIcon />
        <Typography variant="body1">
          {`${numberWithCommas(votes)}`}
          <span className="mobile-hide">{` ${votes === 1 ? `Vote` : `Votes`}`}</span>
        </Typography>
      </div>
    </button>
  )
}
