import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Breadcrumbs } from '../../../components'
import { DesktopContestPetCard } from '../DesktopContestPetCard/DesktopContestPetCard'
import { BioDetails } from '../BioDetails/BioDetails'
import { PhotoDetails } from '../PhotoDetails/PhotoDetails'
import { VoteModal } from '../../../pages'

import { ContestDetails, Pet, ContestPetDetails } from '../../../types'

import './DesktopPetDetails.scss'

export const DesktopPetDetails = ({
  contest,
  pet,
  petContestDetails,
  handleOnClickNext,
  handleOnClickPrevious
}: {
  contest?: ContestDetails | null
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  handleOnClickNext?: () => void
  handleOnClickPrevious?: () => void
}) => {
  const [voteModalOpen, setVoteModalOpen] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams?.get('status') === 'SUCCESS') {
      setVoteModalOpen(true)
    }
  }, [searchParams])

  return (
    <div className="contest-pet-details">
      {!!contest && (
        <Breadcrumbs
          previousOptions={[
            { label: 'Contests', url: '/contests' },
            { label: contest.name, url: `/contests/${contest.contest_id}` }
          ]}
          current={pet.name}
        />
      )}
      <div className="pet-details-content">
        <DesktopContestPetCard
          pet={pet}
          petContestDetails={petContestDetails}
          contest={contest}
          handleOnClickNext={handleOnClickNext}
          handleOnClickPrevious={handleOnClickPrevious}
          onClickVote={() => setVoteModalOpen(true)}
        />
        <BioDetails pet={pet} />
        <PhotoDetails pet={pet} />
      </div>
      {!!contest && (
        <VoteModal
          open={voteModalOpen}
          handleClose={() => setVoteModalOpen(false)}
          contest={contest}
          pet={pet}
          petContestDetails={petContestDetails}
        />
      )}
    </div>
  )
}
