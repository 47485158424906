// Auth Context. Is used to store if the User is authenticated or not.
// This context is used to protect private routes as well as hidding some
// components that will only be rendered if the user is logged in.

import { createContext, useContext, useReducer } from 'react'

interface Action {
  isAuthenticated?: boolean | null
  isLoading?: boolean
}

interface ProviderProps {
  children: React.ReactNode
  options?: Action
}

interface IState {
  isAuthenticated: boolean | null
  isLoading: boolean
}

interface IContext extends IState {
  dispatchAuth: Function
}

const initialState: IState = {
  isAuthenticated: null as any,
  isLoading: true
}

const AuthContext = createContext<IContext | undefined>(undefined)

function AuthReducer(state = initialState, action: Action) {
  return { ...state, ...action }
}

function AuthProvider({ children, options = {} }: ProviderProps) {
  const [stateAuth, dispatchAuth] = useReducer(AuthReducer, {
    ...initialState,
    ...options
  })

  return (
    <AuthContext.Provider value={{ ...stateAuth, dispatchAuth }}>{children}</AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a IDProvider`)
  }
  return context
}
export { AuthProvider, useAuth }
