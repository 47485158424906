import { Dispatch } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useTimer } from 'react-timer-hook'

import { Button, Loading } from '../../../components'

import { ReactComponent as VoteIcon } from '../../../assets/icons/vote.svg'
import { ReactComponent as VotePinkIcon } from '../../../assets/icons/vote-pink.svg'
import { ReactComponent as MealIcon } from '../../../assets/icons/meal.svg'

import { numberWithCommas } from '../../../utils/formatHelpers'
import { VotePackages } from '../../../services/constants'

import './ChooseVotes.scss'

// Types
import { Pet } from '../../../types'

export const VoteRow = ({
  price,
  votes,
  meals,
  pink = false,
  onClick,
  availableAt,
  isRecommended
}: {
  price: number
  votes: number
  meals: number
  pink?: boolean
  onClick: Function
  availableAt?: string
  isRecommended?: boolean
}) => {
  const buttonDisabled = availableAt ? moment(availableAt).isAfter(moment()) : false
  const { hours, seconds, minutes } = useTimer({
    expiryTimestamp: moment(availableAt).toDate(),
    onExpire: () => null
  })

  return (
    <li className="vote-row-item">
      {!pink ? <VoteIcon className="vote-icon" /> : <VotePinkIcon className="vote-icon" />}
      <div className="vote-rows-details">
        <div className="vote-row-details-txt">
          <Typography
            variant="subtitle1"
            style={{ color: isRecommended ? `#FFBC01` : undefined, width: 60 }}>
            {price ? `$${price}` : `FREE`}
          </Typography>
          {!!price && (
            <div className="meals-div">
              <MealIcon />
              <Typography variant="subtitle1">
                {`${meals} ${meals === 1 ? `meal` : `meals`}`}
                <span className="mobile-hide">{` donated`}</span>
              </Typography>
            </div>
          )}
        </div>
        <Button
          onClick={() => onClick(votes, price, meals)}
          text={
            buttonDisabled
              ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
                  seconds
                ).padStart(2, '0')}`
              : `+${numberWithCommas(votes)} votes`
          }
          style={{ width: 150, height: 40, borderRadius: 10 }}
          color={isRecommended ? 'yellow' : pink ? 'pink' : 'purple'}
          disabled={buttonDisabled}
        />
      </div>
    </li>
  )
}

export const ChooseVotes = ({
  setNumberVotes,
  setStep,
  setPrice,
  setMeals,
  pet,
  isLoading,
  freeVoteAvailability,
  handleFreeVote
}: {
  setNumberVotes: Dispatch<number | null>
  setStep: Dispatch<string>
  setPrice: Dispatch<number>
  setMeals: Dispatch<number>
  pet: Pet
  isLoading: boolean
  freeVoteAvailability?: string
  handleFreeVote: () => void
}) => {
  const handleClickOnVotes = (votes: number, price: number, meals: number) => {
    if (votes === 1) {
      return handleFreeVote()
    }
    setNumberVotes(votes)
    setStep('choose-payament-method')
    setPrice(price)
    setMeals(meals)
  }

  return (
    <div className="choose-votes-div">
      <div className="choose-votes-header">
        <Typography variant="h5">{`Vote for ${pet.name}`}</Typography>
      </div>
      <ul>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <VoteRow
              price={0}
              meals={0}
              votes={1}
              onClick={handleClickOnVotes}
              availableAt={freeVoteAvailability}
            />
            {VotePackages.map((p, i) => (
              <VoteRow
                key={`vote-row-${i}`}
                price={p.price}
                meals={p.meals}
                votes={p.votes}
                onClick={handleClickOnVotes}
                pink={p.pink}
                isRecommended={p.isRecommended}
              />
            ))}
          </>
        )}
      </ul>
    </div>
  )
}
