import { Dispatch } from 'react'
import { Typography } from '@mui/material'

import { Button } from '../../../components'

import { Pet } from '../../../types'

import { numberWithCommas } from '../../../utils/formatHelpers'

import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg'

import './SuccessVote.scss'

export const SuccessVote = ({
  setStep,
  pet,
  votes
}: {
  setStep: Dispatch<string>
  pet: Pet
  votes: number
}) => {
  const { name } = pet

  return (
    <div className="success-vote-div">
      <div className="success-vote-content">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <div className="txt-container">
          <Typography variant="h6">Thank you!</Typography>
          <Typography variant="subtitle1">{`You gave ${name} ${
            !votes ? '-- Votes' : votes === 1 ? `1 Vote` : `${numberWithCommas(votes)} Votes`
          }!`}</Typography>
        </div>
      </div>
      <Button
        icon={<ShareIcon />}
        onClick={() => setStep('share')}
        text="Share"
        style={{ maxWidth: 320 }}
      />
    </div>
  )
}
