import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { CheckoutForm } from '../CheckoutForm/CheckoutForm'
import { PetPhoto, Loading } from '../../../components'

import { usePaymentIntentMutation } from '../../../services/queries'

import { getNumberWithOrdinal, numberWithCommas } from '../../../utils/formatHelpers'

// Types
import { Pet, ContestPetDetails, ContestDetails } from '../../../types'

import './PaymentMethod.scss'

//We should init the stripe object
const stripe = loadStripe(
  'pk_test_51LS5BYDdnuKvLuAluOSxfr1PPy0ASLHLkosqadyNTY8jRXXYNh4y6wIAS6Ydf8jDf0M6zosaDHY7R7lcllNdp4Il001YiAy6Ux'
)

const PaymentHeader = ({
  pet,
  place,
  votes
}: {
  pet: Pet
  place?: number | null
  votes?: number | null
}) => (
  <div className="payment-header-div">
    <div className="payment-header-details">
      <PetPhoto
        imageID={pet.photo_main}
        variant="square"
        style={{ width: 75, height: 75, borderRadius: 10 }}
      />
      <div className="details-txt">
        <Typography>
          <b>{`${pet.name}, `}</b>
          {pet.owner_name}
        </Typography>
        {!votes ? (
          <Typography>0 Votes so far</Typography>
        ) : (
          <Typography>{`${place ? getNumberWithOrdinal(place) : '-'} • ${
            votes ?? 0
          } votes`}</Typography>
        )}
      </div>
    </div>
  </div>
)

export const PaymentMethod = ({
  pet,
  petContestDetails,
  contest,
  votesToBuy,
  price,
  meals
}: {
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  contest: ContestDetails
  votesToBuy: number
  price: number
  meals: number
}) => {
  const paymentIntentMutation = usePaymentIntentMutation()
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    paymentIntentMutation.mutate(
      { votes: votesToBuy, petID: pet.id, contestID: contest.contest_id },
      { onSuccess: ({ clientSecret }: { clientSecret: string }) => setClientSecret(clientSecret) }
    )
  }, [])

  return (
    <div className="payment-method-div">
      <PaymentHeader
        pet={pet}
        place={petContestDetails?.overall_rank}
        votes={petContestDetails?.votes}
      />
      <div className="content">
        <Typography variant="h6">{`${numberWithCommas(votesToBuy)} Votes`}</Typography>
        <Typography>{`Help ${pet.name} win the contest by giving ${
          pet.gender === 'M' ? 'him' : 'her'
        } ${numberWithCommas(votesToBuy)} Votes!`}</Typography>
        <Typography variant="subtitle1">
          <b>
            {`Your ${votesToBuy} Votes will buy ${meals} meals for a ${pet.pet_type.toLowerCase()} in a shelter`}
          </b>
        </Typography>
        {clientSecret?.length ? (
          <Elements
            options={{
              clientSecret,
              loader: 'auto',
              appearance: {
                theme: 'flat',
                labels: 'floating',
                variables: {
                  fontFamily: 'Poppins , system-ui, sans-serif',
                  colorText: '#3B3C43',
                  colorPrimary: '#7e73f2'
                }
              }
            }}
            stripe={stripe}>
            <CheckoutForm price={price} contest={contest} pet={pet} votes={votesToBuy} />
          </Elements>
        ) : (
          <div style={{ minHeight: 200, display: 'flex' }}>
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}
