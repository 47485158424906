import { MyPurchasesResposne } from '../../../types'

export const MyPurchases: MyPurchasesResposne = [
  {
    votes: 100,
    price: 1200,
    date: '2022-07-25 15:03:48',
    profile_id: 1,
    contest_entry_id: 2
  },
  {
    votes: 1000,
    price: 10.99,
    date: '2002-08-10',
    profile_id: 1,
    contest_entry_id: 10
  },
  {
    votes: 1000,
    price: 10.99,
    date: '2002-08-10',
    profile_id: 1,
    contest_entry_id: 10
  }
]
