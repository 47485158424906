import { useState } from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import { FaqsList } from '../../../services/constants/FaqsList'

//ASSETS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import './FAQ.scss'

/** This is rendering the FAQ section. Each FAQ is being defined using the FaqsList file */
export const FAQ = () => {
  const [selectedFaq, setSelectedFaq] = useState(-1)
  return (
    <div className="faq">
      <div className="txt-div">
        <Typography variant="h1" sx={{ color: 'white' }}>
          Frequently
        </Typography>
        <Typography variant="h1" sx={{ color: 'white' }}>
          Asked Questions
        </Typography>
      </div>
      <div className="txt-div">
        <Typography variant="h5" sx={{ color: 'white' }}>
          Any other questions - just email us at
        </Typography>
        <Typography variant="h5" sx={{ color: '#FFBC01' }}>
          Bark@PawsContest.com
        </Typography>
      </div>
      <div className="faqs-container">
        {FaqsList.map((faq, i) => (
          <Accordion
            key={`faq-${i}`}
            expanded={selectedFaq === i}
            onChange={() => setSelectedFaq((prev) => (i !== prev ? i : -1))}
            elevation={0}
            className="faq-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="faq-question-txt">
                <b>{faq.question}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: 'white', whiteSpace: 'pre-line' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
}
