import { Dispatch } from 'react'
import { Typography, Checkbox } from '@mui/material'

import './CheckboxInput.scss'

/**
 * CheckboxInput is used to render a Checkbox followed by a quick text label. This is used among the most forms in the application.
 */
export const CheckboxInput = ({
  value,
  setValue,
  label
}: {
  /** if true, the checkbox is going to be filled. */
  value: boolean
  /** Dispatch that is responsible to keep the value state up to date. */
  setValue: Dispatch<boolean>
  /** label text shown on the right of the checkbox */
  label: string
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
  }

  return (
    <div className="checkbox-input-div">
      <Checkbox
        checked={value}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>{label}</Typography>
    </div>
  )
}
