import { useState, useEffect, Dispatch } from 'react'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

// Components
import {
  Loading,
  Button,
  InputImage,
  Input,
  SelectInput,
  CountryInput,
  RadioSelect
} from '../../components'

//Queries
import {
  useFetchPet,
  useFetchAllBreeds,
  useFetchMyProfile,
  usePatchPetMutation
} from '../../services/queries'

// Validators
import { RegexValidators } from '../../utils/Validators'

// Constants
import { StateList } from '../../services/constants'

import { Country } from '../../types'

import './EditPet.scss'

// MyPets is rendering the Edit Pets page. This page is fetching the current pet details and it's going to
// allow each user to edit their pet's info
export const EditPet = () => {
  const { petID = '' } = useParams()
  const navigate = useNavigate()

  const [country, setCountry] = useState<Country>('US')
  const [state, setState] = useState('')
  const [photoMain, setPhotoMain] = useState('')
  const [photo2, setPhoto2] = useState('')
  const [photo3, setPhoto3] = useState('')
  const [photo4, setPhoto4] = useState('')
  const [name, setName] = useState('')
  const [bio, setBio] = useState('')
  const [gender, setGender] = useState<'M' | 'F' | ''>('')
  const [breed, setBreed] = useState('')

  const { data: pet, isLoading: isLoadingPet, isFetched: isFetchedPet } = useFetchPet(petID)
  const { data: allBreeds, isLoading: isLoadingBreeds } = useFetchAllBreeds()
  const { data: profile, isFetched: isFetchedProfile } = useFetchMyProfile()
  const { mutate: patchPet, isLoading: isPatchingPet } = usePatchPetMutation(petID)

  // All the input validators should be here.
  const buttonDisabled =
    !RegexValidators.petCountry.test(country) ||
    !RegexValidators.petState.test(state) ||
    !RegexValidators.petGender.test(gender) ||
    !RegexValidators.petBreed.test(breed) ||
    !RegexValidators.petBio.test(bio) ||
    !RegexValidators.petName.test(name)

  const handleOnClickSave = () => {
    patchPet({
      name,
      country,
      state,
      photoMain,
      photo2,
      photo3,
      photo4,
      bio,
      gender,
      breed: Number(breed)
    })
  }

  useEffect(() => {
    if (pet) {
      setPhotoMain(pet.photo_main)
      setPhoto2(pet.photo_2)
      setPhoto3(pet.photo_3)
      setPhoto4(pet.photo_4)
      setName(pet.name)
      setBio(pet.bio)
      setGender(pet.gender)
      setBreed(String(pet.breed_id))
      setCountry(pet.country as Country)
      setState(pet.state)
    }
  }, [pet])

  // Check if the user owns the pet.
  if (isFetchedPet && isFetchedProfile && pet?.owner_id !== profile.id) {
    return <Navigate to="/my-pets" />
  }

  return (
    <div className="edit-pet-page">
      <Typography variant="h3" className="my-pets-header-wrapper">
        Edit Pet
      </Typography>
      {isLoadingPet || isLoadingBreeds ? (
        <Loading />
      ) : (
        <div className="edit-pet-content">
          <div className="edit-pet-form-wrapper">
            <div>
              <Typography variant="h4" color="var(--purple)">
                {pet?.name}
              </Typography>
              <InputImage
                id="photo-main"
                value={photoMain}
                setValue={setPhotoMain}
                aspectRatio={1}
              />

              <div className="input-container">
                <CountryInput value={country} setValue={setCountry} />
                {country === 'US' && (
                  <SelectInput
                    options={StateList}
                    value={state}
                    setValue={setState}
                    placeholder="State"
                    label="State"
                  />
                )}
              </div>
            </div>
            <div>
              <Typography variant="h6">Info About Your Pet</Typography>
              <div className="input-container">
                <Input label="Pet's Name" value={name} setValue={setName} />
                <RadioSelect
                  options={[
                    { label: 'Good Boy', value: 'M' },
                    { label: 'Good Girl', value: 'F' }
                  ]}
                  value={gender}
                  setValue={setGender as Dispatch<string>}
                />
                <SelectInput
                  options={
                    allBreeds?.[pet?.pet_type === 'Dog' ? 'dogs' : 'cats']?.map((b) => ({
                      label: b.name,
                      value: String(b.id)
                    })) ?? []
                  }
                  value={breed}
                  setValue={setBreed}
                  label="Breed"
                  placeholder="Breed"
                />
                <Input
                  label="Bio"
                  value={bio}
                  setValue={setBio}
                  multiline
                  helperText="Tell us something about your pet"
                />
              </div>
            </div>
          </div>
          <div className="more-photos-section">
            <Typography variant="h6">Pets With More Photos Get More Votes</Typography>
            <div className="more-photos-input-wrapper">
              <InputImage id="photo-2" value={photo2} setValue={setPhoto2} aspectRatio={1} />
              <InputImage id="photo-3" value={photo3} setValue={setPhoto3} aspectRatio={1} />
              <InputImage id="photo-4" value={photo4} setValue={setPhoto4} aspectRatio={1} />
            </div>
          </div>
          <div className="action-bttns-container">
            <Button
              onClick={handleOnClickSave}
              text="Save"
              style={{ margin: '0 auto', maxWidth: 500 }}
              disabled={buttonDisabled}
              isLoading={isPatchingPet}
            />
            <button onClick={() => navigate('/my-pets')}>
              <Typography sx={{ fontWeight: 500, color: 'var(--purple)' }}>Cancel</Typography>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
