import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { RadioSelect, PetRowItem, Loading } from '../../components'

import { useFetchPopularPets } from '../../services/queries'

import './PopularPets.scss'

export const PopularPets = () => {
  const navigate = useNavigate()
  const [type, setType] = useState<'Cat' | 'Dog'>('Dog')
  const { data: pets = [], isLoading } = useFetchPopularPets(type)

  return (
    <div className="popular-pets-page">
      <Typography variant="h3">Popular Pets</Typography>
      <RadioSelect
        options={[
          { label: 'Dogs', value: 'Dog' },
          { label: 'Cats', value: 'Cat' }
        ]}
        value={type}
        setValue={setType}
      />
      <div className="popular-pets-container">
        {isLoading ? (
          <Loading />
        ) : (
          pets.map((p, i) => (
            <PetRowItem
              key={`new-pet-${i}`}
              pet={p}
              onClick={() => navigate(`/pets/${p.pet_id}`)}
            />
          ))
        )}
      </div>
    </div>
  )
}
