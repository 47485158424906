import { Typography } from '@mui/material'

import { Image } from '..'

import IconSvg from '../../assets/icon.svg'

import { FormatImageURL } from '../../utils/FormatImageURL'

import './PetPhoto.scss'

/**
 * PetPhoto is used to render a pet photo. We need to make sure that the imageID is a cloudflare image id.
 */
export const PetPhoto = ({
  /**  CF image id */
  imageID,
  /** we can have multiple variants. we just need to add a new variant in the .scss file */
  variant,
  /** if we need to pass more style props we can use this prop */
  style
}: {
  imageID: string | null
  variant: string
  style?: any
}) => {
  if (!imageID?.length) {
    return (
      <div style={style} className="pet-photo fake">
        <img alt="no-photo" src={IconSvg} width={63} height={50} />
        <Typography variant="h6">No Photo</Typography>
      </div>
    )
  }

  return (
    <Image
      className="pet-photo"
      style={style}
      alt="pet-photo"
      src={FormatImageURL(imageID, variant)}
    />
  )
}
