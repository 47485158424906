import { SearchHistoryReponse } from '../../../types'

export const MySearchHistory: SearchHistoryReponse = [
  {
    owner_name: 'Bernardo S.',
    pet_id: 2,
    pet_name: 'Kaiser',
    photo_main: '47e12b1b-f435-4016-964d-e85b3b673100',
    votes: 20000,
    local_rank: 1,
    overall_rank: 1,
    state: 'AL',
    country: 'US',
    breed_name: 'Boxer'
  }
]
