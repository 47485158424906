import { useState, Dispatch } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  SelectInput,
  Button,
  Input,
  InputImage,
  CountryInput,
  ContestCard,
  RadioSelect
} from '../../../components'

import {
  useFetchCurrentContest,
  useFetchAllBreeds,
  useCreatePetMutation
} from '../../../services/queries'

// Constants
import { StateList } from '../../../services/constants'

// Validators
import { RegexValidators } from '../../../utils/Validators'

import { Country } from '../../../types'

import './CreatePetForm.scss'

// CreatePetForm is rendering the Create Pet Form. This component has multiple input fields
// We are using shared components to render each input. This way the component is simpler to read.
// If all the inputs are valid, the button is no longer disabled. If the button is pressed, we call
// a mutation to handle the API call. On Success, the user is redirected to the My Pets page.
export const CreatePetForm = () => {
  const [country, setCountry] = useState<Country>('US')
  const [state, setState] = useState<string>('')
  const [petName, setPetName] = useState('')
  const [type, setType] = useState<'Dog' | 'Cat'>('Dog')
  const [gender, setGender] = useState<'M' | 'F'>('M')
  const [breed, setBreed] = useState('')
  const [bio, setBio] = useState('')
  const [picture, setPicture] = useState('')

  const { data: currentContest } = useFetchCurrentContest(type)
  const { data: allBreeds } = useFetchAllBreeds()
  const useCreatePet = useCreatePetMutation()
  const navigate = useNavigate()

  // All the input validators should be here.
  const buttonDisabled =
    !RegexValidators.petCountry.test(country) ||
    !RegexValidators.petState.test(state) ||
    !RegexValidators.petType.test(type) ||
    !RegexValidators.petGender.test(gender) ||
    !RegexValidators.petBreed.test(breed) ||
    !RegexValidators.petBio.test(bio) ||
    !RegexValidators.pictureURL.test(picture) ||
    !RegexValidators.petName.test(petName)

  // This is the button action. A mutation will be called to handle the API call
  const handleOnClickPublish = () => {
    useCreatePet.mutate(
      {
        name: petName,
        type,
        gender,
        breed: Number(breed),
        bio,
        country,
        state,
        photo: picture
      },
      {
        onSuccess: () => {
          if (!currentContest) {
            return navigate('/my-pets')
          }
          navigate(`/contests/${currentContest.contest_id}`)
        }
      }
    )
  }

  return (
    <div className="create-pet-form">
      <div className="containers-wrapper">
        <div className="left-container">
          <div className="add-photo-wrapper">
            <InputImage value={picture} setValue={setPicture} id="main-photo" aspectRatio={1} />
          </div>
          <div className="left-containre-inputs">
            <CountryInput value={country} setValue={setCountry} />
            {country === 'US' && (
              <SelectInput
                options={StateList}
                value={state}
                setValue={setState}
                placeholder="State"
                label="State"
              />
            )}
          </div>
          {!!type.length && currentContest && (
            <div className="mobile-hide">
              <ContestCard contest={currentContest} variant="small" />
            </div>
          )}
        </div>
        <div className="right-container">
          <Typography variant="h6">Info About Your Pet</Typography>
          <div className="input-container">
            <Input
              placeholder="Pet's Name"
              value={petName}
              setValue={setPetName}
              error={!RegexValidators.petName.test(petName)}
              label="Pet's Name"
            />
            <RadioSelect
              options={[
                { label: 'Dog', value: 'Dog' },
                { label: 'Cat', value: 'Cat' }
              ]}
              value={type}
              setValue={(newValue: string) => {
                setBreed('')
                setType(newValue as 'Dog' | 'Cat')
              }}
            />
            <SelectInput
              options={
                allBreeds?.[type === 'Dog' ? 'dogs' : 'cats']?.map((b) => ({
                  label: b.name,
                  value: String(b.id)
                })) ?? []
              }
              value={breed}
              setValue={setBreed}
              placeholder="Breed"
              label="Breed"
            />
            <RadioSelect
              options={[
                { label: 'Good Boy', value: 'M' },
                { label: 'Good Girl', value: 'F' }
              ]}
              value={gender}
              setValue={setGender as Dispatch<string>}
            />
            <Input
              helperText="Tell us something about your pet"
              placeholder="Bio"
              label="Bio"
              value={bio}
              setValue={setBio}
              multiline
              error={!RegexValidators.petBio.test(bio)}
            />
          </div>
        </div>
      </div>
      {!!type.length && currentContest && (
        <div className="mobile-participate-contest mobile-show">
          <ContestCard contest={currentContest} variant="big" />
        </div>
      )}
      <div className="publish-bttn-wrapper">
        <Button
          onClick={handleOnClickPublish}
          text="Publish"
          style={{ maxWidth: '320px' }}
          disabled={buttonDisabled}
        />
        <button onClick={() => navigate('/my-pets')}>
          <Typography sx={{ fontWeight: 500, color: 'var(--purple)' }}>Cancel</Typography>
        </button>
      </div>
    </div>
  )
}
