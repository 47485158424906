// Auth endpoints mock data

import { LoginResponse, CheckJWTResponse } from '../../../types'

export const LoginRes: LoginResponse = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9maWxlX2lkIjoxLCJsb2dpbl9pZCI6MiwiaWF0IjoxNjU2MzYzMzc1LCJleHAiOjE2NTg5NTUzNzV9.DYyWePXJD9ipau8kjvBYE-7NxEVl4sHQyvE3UUfXqzY'
}

export const CheckJWTRes: CheckJWTResponse = {
  confirmed: true
}
