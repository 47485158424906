import { useState, useEffect } from 'react'
import { Typography, IconButton } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { Avatar, AddPetButton } from '../../components'
import { AccountMenu } from './AccountMenu'
import { Search } from './Search'

// Hooks
import { useAuth, useLoginModal } from '../../services/hooks'
import { useFetchMyProfile } from '../../services/queries'

// Icons
import Logo from '../../assets/logo.svg'
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg'

import './Header.scss'

/**
 * HeaderButton is the component renders one button used in the navbar. It is used to switch between available tabs.
 */
const HeaderButton = ({ text, tab }: { text: string; tab: string }) => {
  const navigate = useNavigate()
  return (
    <button
      onClick={() => navigate(`/${text.toLowerCase().replace(/ /g, '-')}`)}
      className={`header-bttn ${tab === text.toLowerCase().replace(/ /g, '-') ? `active` : ``}`}>
      <Typography>{text}</Typography>
    </button>
  )
}

/**
 * Header is the main component. Renders the navbar in every main page.
 */
export const Header = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuth()
  const { dispatchLoginModal } = useLoginModal()
  const [tab, setTab] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data: profile } = useFetchMyProfile()

  useEffect(() => {
    setTab(pathname.split('/')?.[1] ?? '')
  }, [pathname])

  // This function is called whenever the user clicks the Profile icon on the righ
  const handleOnClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      return dispatchLoginModal({ isOpen: true })
    }

    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  // This is called whenver the user closes the account menu.
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <nav className="desktop-navbar">
      <button onClick={() => navigate('/')}>
        <img
          className="desktop-navbar-logo"
          alt="Paws Contest"
          src={Logo}
          height={50}
          width={150}
        />
      </button>
      <div className="right-navbar">
        <div className="bttns-container">
          <HeaderButton text="About" tab={tab} />
          <HeaderButton text="Contests" tab={tab} />
          <HeaderButton text="Winners" tab={tab} />
          {isAuthenticated && <HeaderButton text="My Pets" tab={tab} />}
          <AddPetButton />
        </div>
        <div className="search-container">
          <Search />
          <IconButton onClick={handleOnClickAccount}>
            {profile?.profile_picture ? (
              <Avatar imageID={profile.profile_picture} height={32} width={32} />
            ) : (
              <AccountIcon style={{ color: `var(--black)`, width: 26, height: 26 }} />
            )}
          </IconButton>
        </div>
      </div>
      <AccountMenu anchorEl={anchorEl} handleClose={handleCloseMenu} />
    </nav>
  )
}
