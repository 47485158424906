import { useState } from 'react'
import { Typography } from '@mui/material'

// Components
import { Loading, ChoosePetModal, AchievementCard } from '../../components'

//Queries
import { useFetchMyAchievements, useFetchMyPets, useUseAchievement } from '../../services/queries'

import './MyAchievements.scss'

// MyAchievements is rendering the My Achievements page.
export const MyAchievements = () => {
  const useAchievement = useUseAchievement()
  const { data: achievements = [], isLoading } = useFetchMyAchievements()
  const [selectedAchievement, setSelectedAchievement] = useState<number | null>(null)
  const { data: myPets = [], isLoading: isLoadingMyPets } = useFetchMyPets()

  const handleOnClickUse = (id: number) => {
    setSelectedAchievement(id)
  }

  const handlePetOnClick = (petID: number) => {
    if (selectedAchievement)
      useAchievement.mutate(
        { achievementID: selectedAchievement, petID },
        { onSuccess: () => setSelectedAchievement(null) }
      )
  }

  return (
    <div className="my-achievements-page">
      <Typography variant="h3">My Achievements</Typography>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="achievements-container">
          {achievements.map((a) => (
            <AchievementCard
              key={`achievement-card-${a.achievement_type_id}`}
              achievement={a}
              onClick={handleOnClickUse}
            />
          ))}
        </div>
      )}
      <ChoosePetModal
        open={Boolean(selectedAchievement)}
        handleClose={() => setSelectedAchievement(null)}
        pets={myPets}
        onClick={handlePetOnClick}
        isLoading={isLoadingMyPets || useAchievement.isLoading}
      />
    </div>
  )
}
