import { Typography } from '@mui/material'

import { Button } from '../../../components'

// ASSETS
import DecoreLeftIcon from '../../../assets/about/decore-left.png'
import DecoreRightIcon from '../../../assets/about/decore-right.png'
import AboutUsImage from '../../../assets/about/top.png'

import './AboutUs.scss'

export const AboutUs = ({
  handleClickOnEnterYourPet
}: {
  handleClickOnEnterYourPet: () => void
}) => (
  <div className="about-us">
    <img className="decore-left" src={DecoreLeftIcon} />
    <img className="decore-right" src={DecoreRightIcon} />
    <img className="about-us-image" src={AboutUsImage} />
    <div className="txt-section">
      <Typography variant="h1" sx={{ color: '#FFC619' }}>
        THE CUTEST PET WINS $1,000
      </Typography>
      <Typography variant="h5" sx={{ color: 'white' }}>
        Over $50,000 in cash prizes.
      </Typography>
      <Typography variant="h6" sx={{ color: 'white' }}>
        Winners Chosen Every Month
      </Typography>
      <Button
        onClick={handleClickOnEnterYourPet}
        text="ENTER YOUR PET NOW"
        style={{ background: '#FFBC01' }}
        bold
      />
    </div>
  </div>
)
