import { Typography } from '@mui/material'
import moment from 'moment'

import { Loading } from '../../components'

import { useFetchMyPurchases } from '../../services/queries'

import { numberWithCommas } from '../../utils/formatHelpers'

import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'

import './MyPurchases.scss'

export const MyPurchases = () => {
  const { data: purchases = [], isLoading } = useFetchMyPurchases()

  return (
    <div className="my-purchases-page">
      <Typography variant="h3">My Purchases</Typography>
      <div className="purchases-container">
        {isLoading ? (
          <Loading />
        ) : !purchases.length ? (
          <div className="no-purchases-wrapper">
            <Typography variant="h6">{`You don't have any purchases to display`}</Typography>
          </div>
        ) : (
          purchases.map((p, i) => (
            <div key={`purchase-${i}`}>
              <div className="purchase-header">{`${moment(p.date).format('DD MMMM YYYY')}`}</div>
              <div className="purchase-content">
                <div className="left-side">
                  <VoteIcon style={{ width: 40, height: 40 }} />
                  <div>
                    <Typography variant="h6">{`${numberWithCommas(p.votes)} Votes`}</Typography>
                    <Typography variant="subtitle1">{moment(p.date).format('hh:mm A')}</Typography>
                  </div>
                </div>
                <Typography variant="h6">${p.price.toFixed(2)}</Typography>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
