import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import { ContextWrapper } from './utils/ContextWrapper'
import { makeServer } from './services/mocks'

import './index.scss'

// Mock Server Init
// When running npm start locally we want to able to run the application
// without having the backend online. This is done using miragejs. We are
// mocking a web server to intercept all HTTP requests and return mock data
if (['development'].includes(process.env.NODE_ENV ?? '')) {
  makeServer()
}

// It's important to note that we wrapping everything with ContextWrapper component
// This way we make sure that every component inside our application has access to
// all the Hooks created
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ContextWrapper>
      <App />
    </ContextWrapper>
  </React.StrictMode>
)
