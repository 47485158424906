// This is used to rendered FAQS. To add a new FAQ, we just need to add a new object containing the question and the answer
export const FaqsList = [
  {
    question: 'Is it really free to enter?',
    answer: 'Yes!  There is no cost to enter your pet in the contest'
  },
  {
    question: 'Who can enter?',
    answer: 'Any pet owner 21 years or older may enter their pet.  '
  },
  {
    question: 'Can I enter my dog and my cat?',
    answer: 'Absolutely, we love both dogs and cats and have a contest for each of them.'
  },
  {
    question: 'Can I vote for my own pet?',
    answer:
      'Sure - everybody does.  You can vote for your own pet or other pets; you can vote as often as you wish.'
  },
  {
    question: 'How does the judging work?',
    answer:
      'Votes are tabulated at the end of the contest, and the winners are determined by the number of votes.'
  },
  {
    question: 'What are the prizes?',
    answer: `There is a cash prize depending upon the contest.
   1st prize is $1,000
   2nd prize is $500
   3rd prize is $200
   4th prize is $100

Additional non-cash prizes are awarded to other winners`
  },
  {
    question: 'When are the winners determined?',
    answer: 'Contest run each month and winners are determined shortly after the contest.'
  },
  {
    question: 'How is the winner notified?',
    answer: 'All winners are notified by email.'
  },
  {
    question: 'Who do I contact if I have additional questions?',
    answer: 'Contact Bark@PawsContest.com if you have any questions.'
  }
]
