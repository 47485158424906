import { Typography } from '@mui/material'

import { PetPhoto } from '../../../components'

import { Pet } from '../../../types'

import './PhotoDetails.scss'

// PhotoDetails is used to show 3 photos from the current pet
export const PhotoDetails = ({ pet: { photo_2, photo_3, photo_4 } }: { pet: Pet }) => {
  if (!photo_2?.length && !photo_3?.length && !photo_4?.length) {
    return null
  }

  return (
    <div className="photo-details-div">
      <Typography variant="h6">Photos</Typography>
      <div className="photos-container">
        {!!photo_2?.length && <PetPhoto imageID={photo_2} variant="square" />}
        {!!photo_3?.length && <PetPhoto imageID={photo_3} variant="square" />}
        {!!photo_4?.length && <PetPhoto imageID={photo_4} variant="square" />}
      </div>
    </div>
  )
}
