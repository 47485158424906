import { ReactComponent as PawIcon } from '../../assets/icons/paw.svg'

import './Loading.scss'

/**
 * Loading is used across the whole application whenever we need a loading state.
 * If we want a better loading spinner later, we can just change this component and the changes
 * are going to be applied to all the exisiting pages.
 */
export const Loading = () => (
  <div className="loading-wrapper">
    <PawIcon />
  </div>
)
