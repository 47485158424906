// This file has all the Validator helpers needed. This is used mainly to validate input fields
export const RegexValidators = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  pictureURL: /.*/,

  // PET FORM
  petName: /^([a-zA-Z0-9_-\s]){2,60}$/,
  petType: /^Dog|Cat$/,
  petGender: /^F|M$/,
  petBreed: /^\d+$/,
  petBirthday: /^\d\d\/\d\d\/\d\d$/,
  petBio: /^.{0,240}$/,
  petState: /^([a-zA-Z0-9_-]){1,5}$/,
  petCountry: /^([a-zA-Z0-9_-]){1,5}$/
}
