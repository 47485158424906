import { FreeVoteAvailabilityResponse, TodayVotersResponse } from '../../../types'

export const FreeVoteAvailability: FreeVoteAvailabilityResponse = {
  availableAt: '2021-09-26T16:53:52.000Z'
}

export const TodayVoters: TodayVotersResponse = [
  {
    created: '2022-08-27 14:22:05',
    profile_id: 1,
    name: 'Bernardo P.',
    profile_picture: '08114130-9c46-4c06-c927-f747331d6700',
    contest_entry_id: 2,
    pet_id: 2,
    votes: 100,
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00'
  },
  {
    created: '2022-08-27 14:22:05',
    profile_id: 1,
    name: 'Bernardo P.',
    profile_picture: '08114130-9c46-4c06-c927-f747331d6700',
    contest_entry_id: 3,
    pet_id: 1,
    votes: 1000,
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00'
  },
  {
    created: '2022-08-27 14:22:05',
    profile_id: 1,
    name: 'Bernardo P.',
    profile_picture: '08114130-9c46-4c06-c927-f747331d6700',
    contest_entry_id: 4,
    pet_id: 2,
    votes: 100,
    pet_name: 'Rob',
    pet_photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00'
  }
]
