import { MyBonusVotesResponse } from '../../../types'

export const MyBonusVotes: MyBonusVotesResponse = [
  {
    bonus_vote_id: 1,
    pet_id: 1,
    contest_id_earned: 1,
    contest_end_date: '2022-08-24 15:03:48',
    contest_entry_id_earned: 1,
    contest_place: 20,
    contest_place_type: 'NY',
    votes: 100
  },
  {
    bonus_vote_id: 2,
    pet_id: 1,
    contest_id_earned: 1,
    contest_end_date: '2022-08-24 15:03:48',
    contest_entry_id_earned: 1,
    contest_place: 10,
    contest_place_type: 'Global',
    date_used: '2022-08-10 00:00:00',
    contest_entry_used_id: 1,
    votes: 500
  },
  {
    bonus_vote_id: 3,
    pet_id: 1,
    contest_id_earned: 1,
    contest_end_date: '2022-08-24 15:03:48',
    contest_entry_id_earned: 1,
    contest_place: 1,
    contest_place_type: 'Global',
    date_used: '2022-08-10 00:00:00',
    contest_entry_used_id: 1,
    votes: 500
  }
]
