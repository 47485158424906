import { Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { PetPhoto, MedalBadge } from '../../components'

// Constants
import { StateList } from '../../services/constants'

import { ReactComponent as LocationFilledIcon } from '../../assets/icons/location-filled.svg'
import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'

import { numberWithCommas } from '../../utils/formatHelpers'

import { ContestPet } from '../../types'

import './PetCard.scss'

/**
 * Pet Card is similar to the MyPetCard. This is used to render all the pets that aren't displayed inside the MyPets page.
 */
export const PetCard = ({
  pet,
  onClick,
  variant
}: {
  /** the pet object used to display the pet details */
  pet: ContestPet
  /** onClick action */
  onClick?: () => void
  /** it can be either 'all' or 'local'.*/
  variant?: 'all' | 'local'
}) => {
  const { name, photo_main, votes, breed_name, state, local_rank, overall_rank, overall_prize } =
    pet
  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      className="pet-card-div"
      onClick={onClick}
      disabled={!onClick}>
      {overall_prize && (
        <div className="prize-circle">
          <Typography variant="h5">${overall_prize}</Typography>
          <Typography variant="subtitle2">PRIZE</Typography>
        </div>
      )}
      <div className="photo-wrapper">
        <PetPhoto imageID={photo_main} style={{ position: 'absolute' }} variant="square" />
      </div>
      <div className="card-content">
        <div className="card-txt">
          <div className="name-div">
            <Typography variant="h4">{name}</Typography>
            <Typography>({breed_name})</Typography>
          </div>
          <div className="state-div">
            <LocationFilledIcon />
            <Typography>{StateList.find((s) => s.value === state)?.label ?? '--'}</Typography>
          </div>
          <div className="votes-div">
            <VoteIcon />
            <Typography>{`${numberWithCommas(votes)} ${
              votes === 1 ? `Vote` : `Votes`
            }`}</Typography>
          </div>
        </div>
        {!!variant && (
          <div className="rank-div">
            <MedalBadge height={60} place={variant === 'local' ? local_rank : overall_rank} />
          </div>
        )}
      </div>
    </motion.button>
  )
}
