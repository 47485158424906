/* Search API call queries. Currently using React Query */

import { useMutation } from 'react-query'
import { useQuery } from 'react-query'
import { toast } from 'react-hot-toast'

import { fetchResolve, postResolve } from './helper'

import { CustomError, SearchHistoryReponse } from '../../types'

// Query used to get my search history
export function useFetchSearchHistory() {
  return useQuery<SearchHistoryReponse>([`useFetchSearchHistory`], async () =>
    fetchResolve(`/search/history`)
  )
}

// Query to get search results
export function useSearchMutation() {
  return useMutation(
    async (value: string) =>
      await postResolve(`/search`, {
        value
      }),
    {
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      }
    }
  )
}
