import { Typography } from '@mui/material'
import { Icon } from '@iconify/react'

import './Success.scss'

// Succcess is rendering the success page
export const Success = () => (
  <div className="success-page">
    <Typography variant="h2">Success!</Typography>
    <Icon icon="twemoji:partying-face" />
  </div>
)
