import { useParams, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import { Loading, PetCard } from '../../../components'

import { useFetchContestPets } from '../../../services/queries'

import './AllTab.scss'

// AllTab is showing a PetCard per pet participating in the current contest.
// We are fetching all the pets from the current contest and showing them.
export const AllTab = ({ isFinished }: { isFinished?: boolean }) => {
  const navigate = useNavigate()
  const { contestID = '' } = useParams()
  const { data: pets = [], isLoading } = useFetchContestPets(contestID)

  // This will be called whenever a pet card is pressed
  const handlePetCardClick = (petID: number) => {
    navigate(`/contests/${contestID}/pets/${petID}`)
  }

  // If we are waiting from the backend response, we are showing the loading component
  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="all-tab-div">
      {pets.length ? (
        pets.map((pet) => (
          <PetCard
            key={`pet-${pet.id}`}
            pet={pet}
            onClick={!isFinished ? () => handlePetCardClick(pet.id) : undefined}
            variant="all"
          />
        ))
      ) : (
        <div className="no-pets-wrapper">
          <Typography variant="h6">None</Typography>
        </div>
      )}
    </div>
  )
}
