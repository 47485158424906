/* Profile API call queries. Currently using React Query */

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

// CONTEXT
import { useAuth } from '../hooks'

import { fetchResolve, patchResolve, deleteResolve, postResolve } from './helper'

import {
  ProfileResponse,
  MyAchievementsResponse,
  MyPurchasesResposne,
  MyContestsResponse,
  Vote,
  CustomError
} from '../../types'

// Query used to get current user profile
export function useFetchMyProfile() {
  return useQuery<ProfileResponse>([`useFetchMyProfile`], async () => fetchResolve(`/profile`), {
    staleTime: Infinity
  })
}

// Query used to my achievements
export function useFetchMyAchievements() {
  return useQuery<MyAchievementsResponse>([`useFetchMyAchievements`], async () =>
    fetchResolve(`/profile/achievements`)
  )
}

// Fetch all purchases
export const useFetchMyPurchases = () => {
  return useQuery<MyPurchasesResposne>([`useFetchMyPurchases`], async () =>
    fetchResolve(`/profile/purchases`)
  )
}

// Fetch all purchases
export const useFetchMyContests = (type: 'current' | 'finished') => {
  return useQuery<MyContestsResponse>([`useFetchMyPurchases`, type], async () =>
    fetchResolve(`/profile/contests/${type}`)
  )
}

// Mutation used to patch the user profile
export const usePatchMyProfile = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      name,
      email,
      subscribe_notifications,
      subscribe_updates,
      profile_picture
    }: {
      name?: string
      profile_picture?: string
      email?: string
      subscribe_notifications?: boolean
      subscribe_updates?: boolean
    }) =>
      await patchResolve(`/profile`, {
        name,
        email,
        subscribe_notifications,
        subscribe_updates,
        profile_picture
      }),
    {
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['useFetchMyProfile'], data)
        toast.success('Your profile was updated!')
      }
    }
  )
}

// Mutation used to delete your account
export const useDeleteMyProfile = () => {
  const { dispatchAuth } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(async () => await deleteResolve(`/profile`), {
    onError: (error: CustomError) => {
      toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
    },
    onSuccess: () => {
      localStorage.removeItem('token')
      dispatchAuth({ isAuthenticated: false })
      queryClient.setQueryData(['useFetchMyProfile'], null)
      toast.success('Your profile was deleted!')
      navigate('/')
      queryClient.invalidateQueries()
    }
  })
}

// Mutation used to connect the current account to a Facebook user
export const useConnectFacebook = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ accessToken, userID }: { accessToken: string; userID: string }) =>
      await postResolve(`/profile/facebook/connect`, { accessToken, userID }),
    {
      onError: () => {
        toast.error('Could not connect Facebook!')
      },
      onSuccess: (data) => {
        toast.success('Connected Facebook with success!')
        queryClient.setQueryData(['useFetchMyProfile'], data)
      }
    }
  )
}

export function useFetchMyRecentVotes() {
  return useQuery<Vote[]>([`useFetchMyRecentVotes`], async () => fetchResolve(`/profile/my-votes`))
}
