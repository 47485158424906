import { Navigate, Outlet } from 'react-router-dom'

import { Loading } from '../components'

// CONTEXT
import { useAuth } from '../services/hooks'

// PrivateRoute is protecting all the private routes. If the user is not authenticated
// we are redirecting him to the main page. If the user is logged in we let him continue
// using the Outlet component from react router dom
export const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />
  }

  return <Outlet />
}
