import moment from 'moment'
import { Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { PetPhoto } from '../../components'

import { FormatImageURL } from '../../utils/FormatImageURL'
import { getNumberWithOrdinal, numberWithCommas } from '../../utils/formatHelpers'

// Assests
import { ReactComponent as MedalFrame } from '../../assets/icons/medal.svg'

import { ContestEntry } from '../../types'

import './ContestEntryCard.scss'

/**
 * Contest Entry Card is used to display a Card with contest entry details
 */
export const ContestEntryCard = ({
  contestEntry,
  finished,
  onClick
}: {
  contestEntry: ContestEntry
  finished?: boolean
  onClick?: () => void
}) => {
  const {
    contest_start_date,
    contest_end_date,
    contest_image,
    contest_background_color,
    contest_name,
    pet_name,
    pet_photo_main,
    votes,
    overall_rank,
    overall_prize
  } = contestEntry

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      className="contest-entry-card-div"
      style={{
        backgroundColor: contest_background_color,
        backgroundImage: `url("${FormatImageURL(contest_image, 'public')}")`
      }}
      onClick={onClick}
      disabled={!onClick}>
      <Typography id="contest-entry-card-name" variant="h3">
        {contest_name}
      </Typography>
      <Typography variant="subtitle2">
        {`From `}
        <b>{`${moment(contest_start_date).format('MMM Do')}`}</b>
        {` to `}
        <b>{`
  ${moment(contest_end_date).format('MMM Do')}
              `}</b>
      </Typography>
      <div className="contest-entry-bottom-div">
        {!finished && (
          <Typography variant="subtitle2">
            <b>Current Position</b>
          </Typography>
        )}
        <div className="contest-entry-pet-div">
          <div className="contest-entry-pet-photo">
            <PetPhoto
              style={{ width: 48, height: 48, borderRadius: 10, border: `1px solid white` }}
              imageID={pet_photo_main}
              variant="square"
            />
            {finished && overall_rank <= 4 && <MedalFrame className="medal-frame" />}
          </div>
          <div className="contest-entry-pet-details">
            <Typography variant="h6">{pet_name}</Typography>
            <Typography variant="subtitle1">{`${getNumberWithOrdinal(
              overall_rank
            )} • ${numberWithCommas(votes)} Votes`}</Typography>
            {!!overall_prize && (
              <Typography variant="h6">{`Won $${numberWithCommas(overall_prize)}`}</Typography>
            )}
          </div>
        </div>
      </div>
    </motion.button>
  )
}
