import { Typography } from '@mui/material'

import { MedalBadge } from '../../../components'

// ASSETS
import { ReactComponent as MedalIcon } from '../../../assets/contests/medal.svg'
import DecoreLeftIcon from '../../../assets/about/decore-left.png'
import DecoreRightIcon from '../../../assets/about/decore-right.png'
import { ReactComponent as ArrowIcon } from '../../../assets/contests/arrow.svg'

import { numberWithCommas } from '../../../utils/formatHelpers'

import { ContestDetails } from '../../../types'

import './PrizeTab.scss'

// PrizeTableLine is rendering a row in the PrizeTable
// This component has two variants ('light' and 'dark').
// To use the darker version we just need to set the dark prop to true
const PrizeTableLine = ({
  prize,
  label,
  dark
}: {
  prize: number
  label: string
  dark?: boolean
}) => (
  <div className={`prize-table-line ${dark ? 'dark' : ''}`}>
    <Typography className="label-txt">
      <b>{label}</b>
    </Typography>
    <ArrowIcon />
    <Typography>{`${numberWithCommas(prize)} Votes`}</Typography>
  </div>
)

// TopPrizeChip renders a MedalBadge and the prize to a specific contest place.
const TopPrizeChip = ({ prize, label }: { prize: number; label: string }) => (
  <div className="top-prize-chip-div">
    <MedalBadge />
    <div className="txt-div">
      <Typography>
        <b>${numberWithCommas(prize)}</b>
      </Typography>
      <Typography variant="subtitle1">{label}</Typography>
    </div>
  </div>
)

// PrizeTab is rendering the PrizeTable for each contest
export const PrizeTab = ({ contest }: { contest: ContestDetails }) => {
  const {
    prize_1st,
    prize_2nd,
    prize_3rd,
    prize_4th,
    prize_5th_to_10th,
    prize_11th_to_20th,
    prize_21th_to_100th
  } = contest

  return (
    <div className="prize-tab-div">
      <div className="prizes-card">
        <div className="header">
          <MedalIcon />
          <Typography variant="h4" className="first-prize-txt">
            {`1st Place - $${numberWithCommas(prize_1st)}`}
          </Typography>
          <img className="decore-left" src={DecoreLeftIcon} />
          <img className="decore-right" src={DecoreRightIcon} />
        </div>
        <div className="table">
          <div className="top-section">
            <TopPrizeChip prize={prize_2nd} label="2nd Place" />
            <TopPrizeChip prize={prize_3rd} label="3rd Place" />
            <TopPrizeChip prize={prize_4th} label="4th Place" />
          </div>
          <div className="bottom-section">
            <PrizeTableLine prize={prize_5th_to_10th} label="5-10th" />
            <PrizeTableLine prize={prize_11th_to_20th} label="11-20th" dark />
            <PrizeTableLine prize={prize_21th_to_100th} label="21-100th" />
          </div>
        </div>
      </div>
    </div>
  )
}
