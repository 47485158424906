import { useParams, Navigate } from 'react-router-dom'

import { Loading, PetDetails } from '../../components'

import {
  useFetchPet,
  useFetchContestPets,
  useFetchCurrentContest,
  useFetchContestPetDetails
} from '../../services/queries'

import './Pet.scss'

// PetDetails is similar to the ContestPetDetails but we are not getting the contest using the URL params.
// The contest in this page is always the current one. If there's no contest running, we don't show data about it.
export const Pet = () => {
  const { petID = '' } = useParams()
  const { data: pet, isLoading: isLoadingPet } = useFetchPet(petID)
  const {
    data: contest,
    isLoading: isLoadingContest,
    isFetched: isFetchedContest
  } = useFetchCurrentContest(pet?.pet_type ?? '')
  const { data: contestPets = [], isLoading: isLoadingPets } = useFetchContestPets(
    contest?.contest_id
  )
  const { data: petContestDetails, isLoading: isLoadingPetContestDetails } =
    useFetchContestPetDetails(contest?.contest_id, petID)

  // Loading state
  if (
    isLoadingPet ||
    isLoadingContest ||
    !isFetchedContest ||
    isLoadingPetContestDetails ||
    isLoadingPets
  ) {
    return <Loading />
  }

  // If there's no pet, we should return an error page  //TODO
  if (!pet) {
    return <Navigate to="/error" />
  }

  return (
    <PetDetails
      contest={contest}
      pet={pet}
      petContestDetails={petContestDetails}
      contestPets={contestPets}
    />
  )
}
