/* BonusVotes API call queries. Currently using React Query */

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'

import { fetchResolve, postResolve } from './helper'

import { MyBonusVotesResponse, CustomError } from '../../types'

// Query used to get my bonus votes
export function useFetchMyBonusVotes() {
  return useQuery<MyBonusVotesResponse>([`useFetchMyBonusVotes`], async () =>
    fetchResolve(`/profile/bonus-votes`)
  )
}

// Mutation used to patch the bonus vote record
export const useUseBonusVotes = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ bonusVoteID, petID }: { bonusVoteID: number; petID: number }) =>
      await postResolve(`/bonus-vote/${bonusVoteID}/use`, {
        petID
      }),
    {
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      },
      onSuccess: () => {
        toast.success('Bonus Votes were used!')
        queryClient.invalidateQueries('useFetchMyBonusVotes')
        queryClient.invalidateQueries('useFetchMyPets')
      }
    }
  )
}
