import { IconButton } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

// Icons
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'

import './MobileHeader.scss'

export const MobileHeader = ({ hideSearch }: { hideSearch?: boolean }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <nav className="mobile-navbar">
      <button onClick={() => navigate('/')}>
        <Logo height={50} width={150} />
      </button>
      {pathname !== '/search' && !hideSearch && (
        <IconButton className="header-bttn" onClick={() => navigate('/search')}>
          <SearchIcon />
        </IconButton>
      )}
    </nav>
  )
}
