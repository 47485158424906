import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { ContestEntryCard, Loading } from '../../components'
import { VoterItem } from './VoterItem/VoterItem'
import { VoteItem } from './VoteItem/VoteItem'

import {
  useFetchMyContests,
  useFetchTodayVoters,
  useFetchMyRecentVotes
} from '../../services/queries'

import './MyContests.scss'

export const MyContests = () => {
  const navigate = useNavigate()
  const { data: finishedEntries = [], isLoading: isLoadingFinishedEntries } =
    useFetchMyContests('finished')
  const { data: currentEntries = [], isLoading: isLoadingCurrentEntries } =
    useFetchMyContests('current')
  const { data: todayVoters = [], isLoading: isLoadingTodayVoters } = useFetchTodayVoters()
  const { data: myRecentVotes = [], isLoading: isLoadingMyRecentVotes } = useFetchMyRecentVotes()

  return (
    <div className="my-contests-page">
      <div className="my-contests-stats">
        <div className="who-voted-today-container">
          <Typography variant="h5">Who voted today?</Typography>
          {isLoadingTodayVoters ? (
            <Loading />
          ) : !todayVoters.length ? (
            <Typography variant="h6" sx={{ margin: 'auto' }}>
              No votes today
            </Typography>
          ) : (
            todayVoters.map((v, i) => <VoterItem key={`voter-${i}`} vote={v} />)
          )}
        </div>
        <div className="my-recent-votes-container">
          <Typography variant="h5">My Recent Votes</Typography>
          {isLoadingMyRecentVotes ? (
            <Loading />
          ) : !myRecentVotes.length ? (
            <Typography variant="h6" sx={{ margin: 'auto' }}>
              No recent votes
            </Typography>
          ) : (
            myRecentVotes.map((v, i) => <VoteItem key={`voter-${i}`} vote={v} />)
          )}
        </div>
      </div>
      <div className="my-contests">
        <Typography variant="h5">My Contests</Typography>
        <div className="contests-section">
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            CURRENT CONTESTS
          </Typography>
          <div className="contests">
            {isLoadingCurrentEntries ? (
              <Loading />
            ) : currentEntries.length ? (
              currentEntries.map((e, i) => (
                <ContestEntryCard
                  key={`fnished-entry-${i}`}
                  contestEntry={e}
                  onClick={() => navigate(`/contests/${e.contest_id}`)}
                />
              ))
            ) : (
              <Typography variant="h6">No Current Contests</Typography>
            )}
          </div>
        </div>
        <div className="contests-section">
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            FINISHED CONTESTS
          </Typography>
          <div className="contests">
            {isLoadingFinishedEntries ? (
              <Loading />
            ) : finishedEntries.length ? (
              finishedEntries.map((e, i) => (
                <ContestEntryCard
                  key={`current-entry-${i}`}
                  contestEntry={e}
                  finished
                  onClick={() => navigate(`/contests/${e.contest_id}`)}
                />
              ))
            ) : (
              <Typography variant="h6">No Finished Contests</Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
