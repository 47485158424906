import { Typography } from '@mui/material'
import moment from 'moment'

import { ContestDetails } from '../../types'

// ASSESTS
import { ReactComponent as PresentIcon } from '../../assets/icons/present.svg'

import { numberWithCommas } from '../../utils/formatHelpers'

import './CurrentlyWinning.scss'

export const CurrentlyWinning = ({
  overall_rank,
  local_rank,
  contest
}: {
  overall_rank?: number | null
  local_rank?: number | null
  contest: ContestDetails
}) => (
  <div className="currently-winning-container">
    <Typography>Currently winning:</Typography>
    <div className="winning-value">
      <PresentIcon height={24} width={24} />
      <Typography variant="h5" sx={{ fontWeight: 400, color: 'var(--purple)' }}>
        {overall_rank && local_rank
          ? `$${numberWithCommas(
              overall_rank === 1
                ? contest.prize_1st
                : overall_rank === 2
                ? contest.prize_2nd
                : overall_rank === 3
                ? contest.prize_3rd
                : overall_rank === 4
                ? contest.prize_4th
                : 0
            )} + ${numberWithCommas(
              (overall_rank <= 4
                ? 0
                : overall_rank <= 10
                ? contest.prize_5th_to_10th
                : overall_rank <= 20
                ? contest.prize_11th_to_20th
                : overall_rank <= 100
                ? contest.prize_21th_to_100th
                : 0) + (local_rank <= 10 ? 250 : 0)
            )} Votes`
          : '-'}
      </Typography>
    </div>
    <Typography>{`Ending ${moment(contest.end_date).fromNow()}`}</Typography>
  </div>
)
