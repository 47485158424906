import { useNavigate } from 'react-router-dom'

import { DesktopPetDetails } from './DesktopPetDetails/DesktopPetDetails'
import { MobilePetDetails } from './MobilePetDetails/MobilePetDetails'

import { useWindowDimensions } from '../../services/hooks'

import { Pet, ContestPetDetails, ContestDetails, ContestPet } from '../../types'

import './PetDetails.scss'

export const PetDetails = ({
  pet,
  petContestDetails,
  contest,
  contestPets
}: {
  pet: Pet
  petContestDetails: ContestPetDetails | undefined
  contest: ContestDetails | undefined | null
  contestPets?: ContestPet[]
}) => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { id: petID } = pet

  // This is called whenever the next pet button is clicked
  const handleOnClickNext = () => {
    const contestID = contest?.contest_id
    if (!contestPets || !contestID) {
      return
    }

    const index = contestPets.findIndex((p) => p.id === Number(petID))
    if (index == null) {
      return
    }

    // If no pet is found - we should do nothing
    const nextPet = contestPets[(index + 1) % contestPets.length]
    if (!nextPet) {
      return
    }

    // Let's find the next pet and navigate to its page
    navigate(`/contests/${contestID}/pets/${nextPet.id}`)
  }

  // This is called whenever the previous pet button is clicked
  const handleOnClickPrevious = () => {
    const contestID = contest?.contest_id
    if (!contestPets || !contestID) {
      return
    }

    const index = contestPets.findIndex((p) => p.id === Number(petID))
    // If no pet is found - we should do nothing
    if (index == null) {
      return
    }

    // Let's find the next pet and navigate to its page
    const nextPet = contestPets.slice((index - 1) % contestPets.length)[0]
    if (!nextPet) {
      return
    }

    navigate(`/contests/${contestID}/pets/${nextPet.id}`)
  }

  if (width < 890) {
    return (
      <MobilePetDetails
        pet={pet}
        petContestDetails={petContestDetails}
        contest={contest}
        handleOnClickNext={contestPets ? handleOnClickNext : undefined}
        handleOnClickPrevious={contestPets ? handleOnClickPrevious : undefined}
      />
    )
  }

  return (
    <DesktopPetDetails
      contest={contest}
      pet={pet}
      petContestDetails={petContestDetails}
      handleOnClickNext={contestPets ? handleOnClickNext : undefined}
      handleOnClickPrevious={contestPets ? handleOnClickPrevious : undefined}
    />
  )
}
