import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PastContestCard, Loading, Breadcrumbs, Tabs } from '../../components'
import { useFetchContest } from '../../services/queries'

import { AllTab } from '../ContestDetails/AllTab/AllTab'
import { LocalTab } from '../ContestDetails/LocalTab/LocalTab'
import { PrizeTab } from '../ContestDetails/PrizeTab/PrizeTab'

import './PastContestDetails.scss'

// This is the component responsible for rendering the past contest details.
export const PastContestDetails = () => {
  const [tab, setTab] = useState(0)
  const { contestID = '' } = useParams()
  const { data: contest, isLoading } = useFetchContest(contestID)

  // If we are still waiting for the backend response, we need to display a Loading spinner.
  if (isLoading || !contest) {
    return (
      <div className="past-contest-details-page">
        <Breadcrumbs previousOptions={[{ label: 'Winners', url: '/winners' }]} current="" />
        <Loading />
      </div>
    )
  }

  return (
    <div className="past-contest-details-page">
      <Breadcrumbs
        previousOptions={[{ label: 'Winners', url: '/winners' }]}
        current={contest.name}
      />
      <div className="past-contest-content">
        <PastContestCard contest={contest} />
        <Tabs options={['all', 'local', 'prizes']} value={tab} setValue={setTab} />
        {tab == 0 && <AllTab isFinished />}
        {tab == 1 && <LocalTab isFinished />}
        {tab == 2 && <PrizeTab contest={contest} />}
      </div>
    </div>
  )
}
