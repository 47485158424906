import { MyAchievementsResponse } from '../../../types'

export const MyAchievements: MyAchievementsResponse = [
  {
    achievement_type_id: 1,
    achievement_id: null,
    title: 'An incedible story',
    description: 'Upload a pet bio',
    picture: '359281d1-b188-4250-83a5-ac1b22ddbb00',
    votes: 10,
    used: 0
  },
  {
    achievement_type_id: 2,
    achievement_id: null,
    title: 'Journey starts with a single step',
    description: 'Received your first vote',
    picture: 'ce68338e-f3f1-464d-b771-901544861b00',
    votes: 10,
    used: 1
  },
  {
    achievement_type_id: 3,
    achievement_id: 1,
    title: 'True fan',
    description: 'Become fan of Paws Contest Facevook',
    picture: '390a3ae4-1fd3-49b6-c183-01b3f2e95600',
    votes: 15,
    used: 1
  },
  {
    achievement_type_id: 4,
    achievement_id: 3,
    title: 'True follower',
    description: 'Follow Paws Contest on Instagram',
    picture: '4f80bec0-07f9-4564-ac01-2e9cf8c0fa00',
    votes: 15,
    used: 0
  },
  {
    achievement_type_id: 5,
    achievement_id: null,
    title: 'All you need is dog',
    description: 'Upload a Dog',
    picture: 'a9094231-41bb-4467-30bc-0c3137a58200',
    votes: 15,
    used: 0
  },
  {
    achievement_type_id: 6,
    achievement_id: null,
    title: 'Life is better with a Cat',
    description: 'Upload a cat',
    picture: '5c16317c-bd81-4dc1-aff9-e666bf7fe400',
    votes: 15,
    used: 0
  },
  {
    achievement_type_id: 7,
    achievement_id: null,
    title: '#Monslay',
    description: 'Vote on Monday',
    picture: '6237344f-2c08-4fd9-e70e-6c893e68cd00',
    votes: 5,
    used: 0
  }
]
