/* Profile API call queries. Currently using React Query */

import { useQuery } from 'react-query'

import { fetchResolve } from './helper'

import {
  CurrentContestsResponse,
  ContestDetailsResponse,
  ContestPetsResponse,
  ContestPetDetailsResponse,
  PastContestsResponse
} from '../../types'

// Query used to get the latest contest
export function useFetchCurrentContest(type: `Dog` | `Cat` | `Special` | '') {
  return useQuery<CurrentContestsResponse>(
    [`useFetchCurrentContest`, type],
    async () => fetchResolve(`/contest/${type}/current`),
    { enabled: type !== '' }
  )
}

// Query used to get contest details
export function useFetchContest(id: string) {
  return useQuery<ContestDetailsResponse>([`useFetchContest`, id], async () =>
    fetchResolve(`/contest/${id}`)
  )
}

// Query used to get contest pets
export function useFetchContestPets(id: string | number | undefined) {
  return useQuery<ContestPetsResponse>(
    [`useFetchContestPets`, id],
    async () => fetchResolve(`/contest/${id}/pets`),
    {
      enabled: !!id
    }
  )
}

// Query used to get past contests
export function useFetchPastContests(type: `Dog` | `Cat` | `Special`) {
  return useQuery<PastContestsResponse>([`useFetchPastContests`, type], async () =>
    fetchResolve(`/contest/${type}/past`)
  )
}

// Query used to get contest pet
export function useFetchContestPetDetails(
  contestID: string | undefined | number,
  petID: string | undefined | number
) {
  return useQuery<ContestPetDetailsResponse>(
    [`useFetchContestPetDetails`, contestID, petID],
    async () => fetchResolve(`/contest/${contestID}/pets/${petID}`),
    {
      enabled: !!contestID && !!petID
    }
  )
}
