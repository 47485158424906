//Components
import { MyPetCard } from '../../../components'

import { PetSummary } from '../../../types'

import './Pets.scss'

// This component is rendered if the user owns any pet. This will render one
// PetCard per each owened pet.
export const Pets = ({ pets }: { pets: PetSummary[] }) => (
  <div className="my-pets-list">
    {pets.map((pet) => (
      <MyPetCard key={pet.pet_id} pet={pet} />
    ))}
  </div>
)
