import { Typography } from '@mui/material'

import { PetPhoto } from '..'

import { numberWithCommas, getNumberWithOrdinal } from '../../utils/formatHelpers'

import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg'
import { ReactComponent as MedalBadgeIcon } from '../../assets/icons/medal-badge.svg'

import { PetSummary } from '../../types'

import './PetRowItem.scss'

export const PetRowItem = ({ pet, onClick }: { pet: PetSummary; onClick: () => void }) => {
  const { photo_main, pet_name, owner_name, votes, overall_rank } = pet

  return (
    <button className="search-result-item" onClick={onClick}>
      <PetPhoto
        imageID={photo_main}
        variant="square"
        style={{ height: 75, width: 75, borderRadius: 10 }}
      />
      <div className="search-result-txt-div">
        <Typography variant="h6">
          {`${pet_name}, `}
          <span style={{ fontWeight: 400, color: '#3B3C43' }}>{owner_name}</span>
        </Typography>
        <div className="details-div">
          {overall_rank && (
            <div className="details-item">
              <MedalBadgeIcon />
              <Typography variant="subtitle1">
                {`${getNumberWithOrdinal(overall_rank)} - All`}
              </Typography>
            </div>
          )}
          <div className="details-item">
            <VoteIcon />
            <Typography variant="subtitle1">{`${numberWithCommas(votes)} Votes`}</Typography>
          </div>
        </div>
      </div>
    </button>
  )
}
