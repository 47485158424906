import {
  CurrentContestsResponse,
  ContestDetailsResponse,
  ContestPetsResponse,
  ContestPetDetailsResponse
} from '../../../types'

export const CurrentContests: CurrentContestsResponse = {
  contest_id: 120,
  name: 'Test Contest',
  type: 'Dog',
  start_date: '2022-04-30',
  end_date: '2022-09-30 19:24:02',
  prize_1st: 1000,
  prize_2nd: 500,
  prize_3rd: 200,
  prize_4th: 100,
  prize_5th_to_10th: 1500,
  prize_11th_to_20th: 1000,
  prize_21th_to_100th: 500,
  contestants: 1000,
  image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
  background_color: '#51A0AB'
}

export const ContestDetails: ContestDetailsResponse = {
  contest_id: 1,
  name: 'Test Contest',
  type: 'Dog',
  start_date: '2022-07-13 19:17:41',
  end_date: '2022-09-12 19:17:41',
  prize_1st: 1000,
  prize_2nd: 500,
  prize_3rd: 200,
  prize_4th: 100,
  prize_5th_to_10th: 1500,
  prize_11th_to_20th: 1000,
  prize_21th_to_100th: 500,
  image: 'c33a802b-dd24-47f5-9201-fe47041ff400',
  contestants: 2,
  background_color: '#51A0AB'
}

export const ContestPets: ContestPetsResponse = [
  {
    id: 2,
    owner_name: 'Bernardo',
    breed_name: 'Affenpinscher',
    name: 'Manel',
    state: 'NY',
    country: 'US',
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 1000,
    local_rank: 1,
    overall_rank: 1,
    overall_prize: null,
    state_prize: null
  },
  {
    id: 1,
    owner_name: 'Bernardo',
    breed_name: 'Affenpinscher',
    name: 'Lucas',
    state: 'NY',
    country: 'US',
    photo_main: '2b0c243a-5c8a-4a1e-697a-38d63844be00',
    votes: 0,
    local_rank: 2,
    overall_rank: 2,
    overall_prize: null,
    state_prize: null
  }
]

export const ContestPetDetails: ContestPetDetailsResponse = {
  id: 2,
  votes: 1000,
  local_rank: 1,
  overall_rank: 1,
  overall_prize: null,
  state_prize: null
}
