import { Typography } from '@mui/material'

import { Button } from '../../../components'

// ASSETS
import HowItWorksBackground from '../../../assets/about/how-it-works-background.png'
import HowItWorksImage from '../../../assets/about/how-it-works.png'

import './HowItWorks.scss'

/** HowItWorks section in the About page */
export const HowItWorks = ({
  handleClickOnEnterYourPet
}: {
  handleClickOnEnterYourPet: () => void
}) => (
  <div className="how-it-works">
    <div
      className="how-it-works-background"
      style={{ backgroundImage: `url(${HowItWorksBackground})` }}></div>
    <img id="how-it-works-image" src={HowItWorksImage} />
    <div className="txt-section">
      <div className="sub-section">
        <Typography variant="h1">How it Works</Typography>
        <Typography variant="h5">Register, upload your pet photos & win prizes!</Typography>
      </div>
      <Button onClick={handleClickOnEnterYourPet} text="ENTER YOUR PET" />
    </div>
  </div>
)
