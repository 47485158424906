import { useState, Dispatch } from 'react'
import { toast } from 'react-hot-toast'
import { CircularProgress, IconButton, Typography } from '@mui/material'
import CSS from 'csstype'

import { ImageCropper } from './Cropper'

import { ReactComponent as AddPhotoIcon } from '../../assets/my-account/add-photo.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'

import { fetchResolve } from '../../services/queries'
import { FormatImageURL } from '../../utils/FormatImageURL'

import './InputImage.scss'

export const InputImage = ({
  value,
  setValue,
  style,
  id,
  aspectRatio
}: {
  value: string
  setValue: Dispatch<string>
  style?: CSS.Properties
  id: string
  aspectRatio?: number
}) => {
  const [openCrop, setOpenCrop] = useState<boolean>(true)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [selectedImg, setSelectedImg] = useState<string | null>(null)

  const onInputChange = async (e: any) => {
    try {
      const file = e.target.files[0]

      if (!file) {
        return
      }
      console.log(file.type)

      if (file.size > 40000000) {
        toast.error('File should be smaller than 4MB!')
        return
      }

      const objectUrl = URL.createObjectURL(file)

      if (!aspectRatio) {
        return await uploadImage(objectUrl)
      }

      setSelectedImg(objectUrl)
      setOpenCrop(true)
    } catch (e) {
      toast.error('Error uploading image. Try again!')
    }
  }

  const uploadImage = async (img: string) => {
    setIsLoadingUpload(true)
    try {
      const uploadURL = await fetchResolve(`/images/direct-upload`)

      let blob = await fetch(img).then((r) => r.blob())
      let formData = new FormData()
      formData.append('file', blob, 'pet-photo')

      // Upload value
      const res = await fetch(uploadURL, {
        method: 'POST',
        body: formData
      })
      const { result } = await res.json()

      setValue(result.id)
    } catch (e) {
      toast.error('Error uploading image. Try again!')
    } finally {
      setIsLoadingUpload(false)
    }
  }

  return (
    <div className="input-image" style={style}>
      {isLoadingUpload ? (
        <CircularProgress size={40} />
      ) : value !== '' ? (
        <>
          <label htmlFor={`file-upload-${id}`} className="edit-button">
            <img src={FormatImageURL(value, 'square')} alt="Photo" className="photo" />
          </label>
          <input
            id={`file-upload-${id}`}
            data-testid="file-upload"
            type="file"
            onChange={onInputChange}
            accept="image/*"
          />
          <IconButton onClick={() => setValue('')} className="trash-bttn">
            <TrashIcon />
          </IconButton>
        </>
      ) : (
        <>
          <label htmlFor={`file-upload-${id}`} className="edit-button">
            <AddPhotoIcon />
          </label>
          <input
            id={`file-upload-${id}`}
            data-testid="file-upload"
            type="file"
            onChange={onInputChange}
            accept="image/*"
          />
        </>
      )}
      <Typography variant="caption" className="helper-txt">
        .JPG, .PNG - Limit 4MB
      </Typography>
      <ImageCropper
        isOpen={openCrop}
        setIsOpen={setOpenCrop}
        imgURL={selectedImg}
        handleUpload={(img: string) => {
          setOpenCrop(false)
          uploadImage(img)
        }}
      />
    </div>
  )
}
