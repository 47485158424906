import { useState, Dispatch, useEffect } from 'react'
import { IconButton, Typography, Modal } from '@mui/material'
import { useTimer } from 'react-timer-hook'
import moment from 'moment'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useSearchParams } from 'react-router-dom'

import { Button, Input } from '..'

// UTILS
import { RegexValidators } from '../../utils/Validators'

// Hooks
import { useLoginModal } from '../../services/hooks'
import {
  useLoginMutation,
  useCheckJwtMutation,
  useResendConfirmationMutation,
  useFacebookLoginMutation
} from '../../services/queries'

// Assets
import { ReactComponent as ConfirmEmailIcon } from '../../assets/welcome-modal/No Message illustration.svg'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-square.svg'
import { ReactComponent as EmailIcon } from '../../assets/icons/email-fill.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

import './WelcomeModal.scss'

/**
 * WelcomeStep renders the first step of the Login modal. This step allows the user to choose
 * between logging in using Facebook or a normal email account.
 */
const WelcomeStep = ({ setStep }: { setStep: Dispatch<any> }) => {
  const { dispatchLoginModal } = useLoginModal()
  const facebookLoginMutation = useFacebookLoginMutation()

  // This function is called whenever a user logs in using Facebook with success.
  const facebookCallback = ({ accessToken, userID }: any) => {
    facebookLoginMutation.mutate(
      { accessToken, userID },
      { onSuccess: () => dispatchLoginModal({ isOpen: false }) }
    )
  }

  return (
    <>
      <Typography variant="h6" className="header-txt">
        Welcome
      </Typography>
      <Typography variant="subtitle2">Please login or register.</Typography>
      <div className="bttns-container">
        <FacebookLogin
          appId="1056617595241098"
          fields="id,short_name,gender,first_name,last_name,name,email,picture"
          callback={facebookCallback}
          isMobile={false}
          render={({ onClick }: { onClick: any }) => (
            <Button
              onClick={onClick}
              text="Facebook"
              icon={<FacebookIcon />}
              color="blue"
              isLoading={facebookLoginMutation.isLoading}
            />
          )}
        />
        <Button
          onClick={() => setStep('email-form')}
          text="Email"
          icon={<EmailIcon />}
          isLoading={facebookLoginMutation.isLoading}
        />
      </div>
    </>
  )
}

/**
 * EmailFormStep is used to display an input to the user. The user must fill this input with his email.
 * A valid email must be provided.
 */
const EmailFormStep = ({ setStep }: { setStep: Dispatch<any> }) => {
  const loginMutation = useLoginMutation()
  const [email, setEmail] = useState('')
  const buttonDisabled = !email.match(RegexValidators.email)

  // this function is called whenever the  button is pressed. Will call the loginMutation.
  // On success, the mutation should go to the next step.
  const onClick = () => {
    if (!buttonDisabled) {
      loginMutation.mutate(
        { email },
        {
          onSuccess: () => setStep('confirm')
        }
      )
    }
  }

  return (
    <>
      <Typography variant="h6" className="header-txt">
        Confirm Email Address
      </Typography>
      <Typography variant="subtitle2">Please provide your email address</Typography>
      <div className="input-wrapper">
        <Input placeholder="Email" value={email} setValue={setEmail} onClickEnter={onClick} />
      </div>
      <Button disabled={buttonDisabled} onClick={onClick} text="Continue" />
    </>
  )
}

/**
 * This is the last step. After a user enters a valid email, a confirmation is sent via email
 * After confirming the email, the user should be able to hit the "Continue" button to login.
 */
const ConfirmStep = () => {
  const { dispatchLoginModal } = useLoginModal()
  const checkJwtMutation = useCheckJwtMutation()
  const resendConfirmationMutation = useResendConfirmationMutation()
  const {
    seconds,
    minutes,
    isRunning,
    restart: restartTimer
  } = useTimer({
    expiryTimestamp: moment().add({ seconds: 90 }).toDate(),
    onExpire: () => null
  })

  // This function will call the resend endpoint. This endpoint should send another email confirmation to the user's inbox.
  const onClickResend = () => {
    resendConfirmationMutation.mutate(
      {},
      {
        onSuccess: () => {
          const time = moment().add({ seconds: 90 })
          restartTimer(time.toDate())
        }
      }
    )
  }

  // This function will call the check jwt endpoint. This endpoint will return true whenever the user token is confirmed.
  // If true is returned the user logs in with success and the modal will close automatically
  const onClickContinue = () => {
    checkJwtMutation.mutate(
      {},
      {
        onSuccess: ({ confirmed }) => {
          if (confirmed) {
            dispatchLoginModal({ isOpen: false })
          }
        }
      }
    )
  }

  return (
    <>
      <Typography variant="h6" className="header-txt">
        Confirm Email Address
      </Typography>
      <Typography variant="subtitle2">Open the email and confirm your email address</Typography>
      <ConfirmEmailIcon style={{ marginTop: 12 }} />
      {isRunning && (
        <div className="timer-div">
          <Typography variant="body2">Resend email will be available in:</Typography>
          <Typography variant="body2">
            <span>{`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</span>
            sec
          </Typography>
        </div>
      )}
      <div className="bttns-container">
        {!isRunning && (
          <Button
            onClick={onClickResend}
            text="Resend Email"
            isLoading={resendConfirmationMutation.isLoading}
          />
        )}
        <Button onClick={onClickContinue} text="Continue" />
      </div>
      <Typography variant="body2">
        Please keep it opened until you’ve confirmed your email
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        Be sure to check your SPAM folder
      </Typography>
    </>
  )
}

/**
 * The main login modal component. Is used to render all the different tabs inside the same modal.
 */
export const WelcomeModal = () => {
  const { isOpen, dispatchLoginModal } = useLoginModal()
  const [step, setStep] = useState<'welcome' | 'email-form' | 'confirm'>('welcome')
  const [searchParams, setSearchParams] = useSearchParams()

  const handleOnClickClose = () => {
    searchParams.delete('next')
    setSearchParams(searchParams)
    dispatchLoginModal({ isOpen: false })
  }

  useEffect(() => {
    setStep('welcome')
  }, [isOpen])

  return (
    <Modal open={isOpen} onClose={() => null} disableScrollLock>
      <div className="welcome-modal-div">
        <IconButton onClick={handleOnClickClose} className="close-bttn">
          <CloseIcon />
        </IconButton>
        {step === 'welcome' && <WelcomeStep setStep={setStep} />}
        {step === 'email-form' && <EmailFormStep setStep={setStep} />}
        {step === 'confirm' && <ConfirmStep />}
      </div>
    </Modal>
  )
}
