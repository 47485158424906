import { Dispatch, useState, useEffect } from 'react'
import { MenuItem, FormControl, InputLabel, Select, OutlinedInput } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

/**
 * SelectInput is a customized select input which allows the user to choose from a list of values.
 */
export const SelectInput = ({
  value,
  setValue,
  options,
  placeholder,
  disabled,
  label
}: {
  value: string
  setValue: Dispatch<string>
  options: { label: string; value: string }[]
  placeholder: string
  disabled?: boolean
  label?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
  }

  useEffect(() => {
    const handler = () => {
      setIsOpen(false)
    }
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])

  return (
    <FormControl fullWidth>
      <InputLabel sx={{ opacity: disabled ? 0.3 : 0.7 }}>{label}</InputLabel>
      <Select
        input={
          <OutlinedInput
            label={label}
            disabled={disabled}
            sx={{
              '&': {
                '& > fieldset': { borderColor: '#777777', borderRadius: 2 }
              },
              '&:hover': {
                '& > fieldset': {
                  borderColor: 'var(--purple)'
                }
              }
            }}
          />
        }
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        MenuProps={{
          disableScrollLock: true
        }}>
        {options.map((o) => (
          <MenuItem key={`option-#${o.value}`} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
