import { useParams, Navigate } from 'react-router-dom'

import { Loading, PetDetails } from '../../components'

import {
  useFetchContestPets,
  useFetchContest,
  useFetchContestPetDetails,
  useFetchPet
} from '../../services/queries'

import './ContestPetDetails.scss'

// This is the page responsible for rendering all the pet details.
// We can reach this page only from a contest page
export const ContestPetDetails = () => {
  const { contestID = '', petID = '' } = useParams()
  const { data: pet, isLoading: isLoadingPet } = useFetchPet(petID)
  const { data: contestPets = [], isLoading: isLoadingPets } = useFetchContestPets(contestID)
  const { data: petContestDetails, isLoading: isLoadingPetContestDetails } =
    useFetchContestPetDetails(contestID, petID)
  const { data: contest, isLoading: isLoadingContest } = useFetchContest(contestID)

  // If we are still wating for backend data, a spinner is being displayed to the user
  if (isLoadingPets || isLoadingContest || isLoadingPet || isLoadingPetContestDetails) {
    return <Loading />
  }

  if (!pet || !contest) {
    return <Navigate to="/error" />
  }

  return (
    <PetDetails
      contest={contest}
      pet={pet}
      petContestDetails={petContestDetails}
      contestPets={contestPets}
    />
  )
}
