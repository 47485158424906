import moment from 'moment'
import { Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { ContestDetails } from '../../types'

import { FormatImageURL } from '../../utils/FormatImageURL'
import { numberWithCommas } from '../../utils/formatHelpers'

import './ContestCard.scss'

/**
 * Contest Details is used to display a Card with Contest details.
 */
export const ContestCard = ({
  contest,
  variant = 'big',
  onClick
}: {
  /** Contest Object */
  contest: ContestDetails
  /** This component has to Css variant 'big' or 'small' */
  variant?: 'big' | 'small'
  /** onClick action */
  onClick?: () => void
}) => {
  const { start_date, end_date, prize_1st, contestants, image, name } = contest

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      className={`contest-card-div ${variant}`}
      style={{
        backgroundColor: contest.background_color,
        backgroundImage: `url("${FormatImageURL(image, 'public')}")`
      }}
      disabled={!onClick}
      onClick={onClick}>
      <Typography variant={variant === 'small' ? 'h4' : 'h2'}>{name}</Typography>
      <div>
        <Typography variant="subtitle1">
          {`From `}
          <b>{`${moment(start_date).format('MMM Do')}`}</b>
          {` to `}
          <b>{`
${moment(end_date).format('MMM Do')}
            `}</b>
        </Typography>
        {variant === 'big' && (
          <Typography variant="subtitle1">
            {`At `}
            <b>{`${moment(end_date).format('h:mm A')}`}</b>
          </Typography>
        )}
        <Typography variant="subtitle1">
          <b>{`$${numberWithCommas(prize_1st)} to win • ${numberWithCommas(
            contestants
          )} contestants`}</b>
        </Typography>
      </div>
      <Typography variant="subtitle1">
        <b>{`Ending ${moment(end_date).fromNow()}`}</b>
      </Typography>
    </motion.button>
  )
}
