import { Fab, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Hooks
import { useAuth, useLoginModal } from '../../services/hooks'

// Assets
import { ReactComponent as PawIcon } from '../../assets/icons/paw.svg'

import './AddPetButton.scss'

export const AddPetButton = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const { isAuthenticated } = useAuth()
  const { dispatchLoginModal } = useLoginModal()

  const handleOnClick = () => {
    if (!isAuthenticated) {
      setSearchParams({ next: '/my-pets/create' })
      dispatchLoginModal({ isOpen: true })
      return
    }

    navigate('/my-pets/create')
  }

  return (
    <div className="add-pet-fab mobile-hide">
      <Fab variant="extended" color="primary" onClick={handleOnClick}>
        <PawIcon style={{ width: 16, height: 16 }} />
        <Typography className="fab-txt" variant="subtitle1">
          Add Pet
        </Typography>
      </Fab>
    </div>
  )
}
