import { useQueryClient } from 'react-query'
import { Menu, MenuItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// CONTEXT
import { useAuth } from '../../../services/hooks'

//Assets
import { ReactComponent as AccountIcon } from '../../../assets/icons/account.svg'
import { ReactComponent as DiplomaIcon } from '../../../assets/icons/diploma.svg'
import { ReactComponent as FilesIcon } from '../../../assets/icons/files.svg'
import { ReactComponent as PurchaseTagIcon } from '../../../assets/icons/purchase-tag.svg'
import { ReactComponent as PresentIcon } from '../../../assets/icons/present-outline.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg'

import './AccountMenu.scss'

/** AccountMenu is to render the user menu shown as soon as a logedin user clicks the profile button on the top right */
export const AccountMenu = ({
  anchorEl,
  handleClose
}: {
  anchorEl: null | HTMLElement
  handleClose: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { dispatchAuth } = useAuth()

  // Here we need to remove the token from the localstorage.
  const handleLogout = () => {
    localStorage.removeItem('token')
    dispatchAuth({ isAuthenticated: false })
    queryClient.setQueryData(['useFetchMyProfile'], null)
    navigate('/')
    queryClient.invalidateQueries()
  }

  return (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        className: 'account-menu',
        elevation: 0,
        sx: {
          overflow: 'visible',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            borderTop: '1px solid var(--border)',
            borderLeft: '1px solid var(--border)'
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem onClick={() => navigate('/my-account')}>
        <AccountIcon />
        <Typography>My Account</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate('/my-achievements')}>
        <DiplomaIcon />
        <Typography>My Achievements</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate('/my-contests')}>
        <FilesIcon />
        <Typography>My Contests</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate('/my-purchases')}>
        <PurchaseTagIcon />
        <Typography>My Purchases</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate('/my-bonus-votes')}>
        <PresentIcon />
        <Typography>My Bonus Votes</Typography>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <LogoutIcon />
        <Typography>Logout</Typography>
      </MenuItem>
    </Menu>
  )
}
