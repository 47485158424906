import { Dispatch } from 'react'
import { Tabs, Tab } from '@mui/material'

import './RadioSelect.scss'

/**
 * RadioSelect is a component that allows the user to choose from different options.
 */
export const RadioSelect = ({
  options,
  value,
  setValue
}: {
  /** all the options available that are going to be shown to the user  */
  options: { label: string; value: string }[]
  /** current value */
  value: string
  /** Dispatch action that is handling the current value state */
  setValue: Dispatch<any>
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(options[newValue].value)
  }

  return (
    <Tabs
      value={options.findIndex((o) => value === o.value)}
      onChange={handleChange}
      scrollButtons="auto"
      variant={'scrollable'}
      TabIndicatorProps={{ style: { display: 'none' } }}
      className="radio-select-div">
      {options.map((o) => (
        <Tab key={`tab-${o.value}`} label={o.label} />
      ))}
    </Tabs>
  )
}
