import { Typography } from '@mui/material'

import { Avatar } from '../../../components'

import { Pet } from '../../../types'

import './BioDetails.scss'

// BioDetails is reponsible for rendering the bio from the current pet
export const BioDetails = ({ pet: { owner_name, owner_picture, bio } }: { pet: Pet }) => (
  <div className="pet-bio-div">
    <div className="owner-info">
      <Avatar imageID={owner_picture} />
      <Typography variant="h5">{owner_name}</Typography>
    </div>
    <Typography>{bio}</Typography>
  </div>
)
