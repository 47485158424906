import { Link } from 'react-router-dom'
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material'

import './Breadcrumbs.scss'

/**
 * Breadcrumbs are used to render pages with some context behind. For example, when you are seeing a Pet Details page
 * from a contest, you need to be able to return to the contest page. This component is what makes this action possible.
 * The user can jump to past visited pages clicking on each breadcrumb.
 */
export const Breadcrumbs = ({
  previousOptions,
  current
}: {
  /** PreviousOptions are used to render all the clickable components */
  previousOptions: { label: string; url: string }[]
  /** Current is the current page which is not going to be clickable. */
  current: string
}) => (
  <MUIBreadcrumbs sx={{ marginTop: '8px', marginBottom: '16px' }}>
    {previousOptions.map(({ label, url }) => (
      <Link key={`breadcrumbs-link-${label}`} to={url}>
        {label}
      </Link>
    ))}
    <p>{current}</p>
  </MUIBreadcrumbs>
)
