import { useRef, useState, Dispatch } from 'react'
import Cropper from 'react-cropper'
import { Modal } from '@mui/material'

import { Button } from '../../components'

import 'cropperjs/dist/cropper.css'
import './Cropper.scss'

export const ImageCropper = ({
  isOpen,
  setIsOpen,
  imgURL,
  handleUpload
}: {
  isOpen: boolean
  setIsOpen: Dispatch<boolean>
  imgURL: string | null
  handleUpload: Function
}) => {
  const cropperRef = useRef<any>(null)
  const [croppedImg, setCroppedImg] = useState('')
  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    setCroppedImg(cropper.getCroppedCanvas().toDataURL())
  }

  if (!imgURL) {
    return null
  }

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} disableScrollLock>
      <div className="cropper-main-div">
        <Cropper
          src={imgURL}
          style={{
            width: '100%',
            height: 'calc(100% - 48px - 12px)'
          }}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          responsive={true}
          autoCropArea={1}
          aspectRatio={1}
          checkOrientation={false}
        />
        <Button
          onClick={() => handleUpload(croppedImg)}
          text="Upload"
          style={{ margin: '0px auto', maxWidth: 300 }}
        />
      </div>
    </Modal>
  )
}
