/* Achievement API call queries. Currently using React Query */

import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'

import { postResolve } from './helper'

import { CustomError } from '../../types'

// Mutation used to patch the achievement record
export const useUseAchievement = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ achievementID, petID }: { achievementID: number; petID: number }) =>
      await postResolve(`/achievement/${achievementID}/use`, {
        petID
      }),
    {
      onError: (error: CustomError) => {
        toast.error(error.data?.error?.message ?? 'Unexpected error occurred. Please try again!')
      },
      onSuccess: () => {
        toast.success('Achievement Votes were used!')
        queryClient.invalidateQueries('useFetchMyAchievements')
        queryClient.invalidateQueries('useFetchMyPets')
      }
    }
  )
}
